import React, { Component } from "react";
import { MDBRow, MDBFormInline, MDBIcon, MDBInput, MDBCard, MDBTabContent, MDBTabPane, MDBContainer, MDBNavItem, MDBNavLink, MDBNav, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBCardBody, MDBBreadcrumbItem, MDBCol } from 'mdbreact';
import { unstable_renderSubtreeIntoContainer } from "react-dom";
import DatePicker from "react-datepicker";
import HttpAPI from '../../common/api_calls';
import ApiUtils from '../../common/api_utils';
import APIResponse from '../../common/APIResponse'
import APILoader from '../../common/APILoader'
import CustomBreadCrumb from '../sections/CustomBreadCrumb'
import CustomCheckBox from '../sections/CustomCheckBox'
import Select from 'react-select';
import * as moment from 'moment';
import { useHistory } from "react-router-dom";
import "../Styleing.css";
class AddRCModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadCrumbLabel: '',
            startdate: new Date(),
            purchaseNumber: '',
            rcBookId: '',
            stock: '',
            company: '',
            model: '',
            customerName: '',
            loandetail: '',
            parsingDt: null,
            bikeNumber: '',
            engineNumber: '',
            chasisNumber: '',
            rtoAddress: '',
            apiResponse: false,
            apiResponseModalOpen: false,
            loadingIsOpen: false,
            apiResponseMsg: '',
            companyData: [],
            modelData: [],
            companyName: '',
            modelName: '',
            filterModel: [],
            rcdata: [],
            smsValue: false,
            id: 0,
            rcId: 0,
            purchasRCId: 0,
            mobileNumber: '',
            allDocReceived: false,
            idProofReceived: false,
            apiResponse: false,
            apiResponseModalOpen: false,
            isFinance: false,
            isSubmittedRc:false,
            disableDocReceived:false,
            apiResponseMsg: '',
            contactData: [],
            purchaseData: [],
            allPurchaseData: [],
            purchaseRecord: {},
            isPurchaseDis: false,
            selectedForm35Copy: '',
            form352Copy: false,
            form351Copy: false,
            noc: false,
            kyc: false,
            selectedpurchaseNumber: '',
            selectedbikeNumber: '',
            purchaseNumOptions: [{ id: 0, name: 'purchaseNumber', label: "", value: "" }],
            bikeNumberOptions: [{ id: 0, name: 'bikeNumber', label: "", value: "" }],
            errorMap: {
                'purchaseNumber': { 'valid': true, 'error': '' },
                'rcBookId': { 'valid': true, 'error': '' },
                'stock': { 'valid': true, 'error': '' },
                'customer': { 'valid': true, 'error': '' },
                'company': { 'valid': true, 'error': '' },
                'model': { 'valid': true, 'error': '' },
                'rcRecvdDate': { 'valid': true, 'error': '' },
                'bikeNumber': { 'valid': true, 'error': '' },
                'engineNumber': { 'valid': true, 'error': '' },
                'chasisNumber': { 'valid': true, 'error': '' },
                'rtoAddress': { 'valid': true, 'error': '' },
            },
        }
        this.purchaseNumInput = React.createRef();
        this.rcBookIdInput = React.createRef();
        this.stockInput = React.createRef();
        this.customerInput = React.createRef();
        this.companyInput = React.createRef();
        this.modelInput = React.createRef();
        this.rcRecvdDateInput = React.createRef();
        this.bikeNumberInput = React.createRef();
        this.engineNumberInput = React.createRef();
        this.chasisNumberInput = React.createRef();
        this.rtoAddressInput = React.createRef();
    }
    toggle = () => {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });

    }
    componentDidMount() {
        this.renderDataFromServer();
    }
    renderDataFromServer() {

        window.localStorage.setItem('openRCFromId', 0)
        this.setState({ loadingIsOpen: true })

        if (window.localStorage.getItem('editRCId') > 0) {
            this.setState({ breadCrumbLabel: 'Edit RC' })
        } else {
            this.setState({ breadCrumbLabel: 'Add RC' })
        }

        if (window.localStorage.getItem('editRCId') > 0) {
            HttpAPI.getPurchaseByRCId(window.localStorage.getItem('editRCId')).then(
                response => {
                    var response = response.data;
                    let pdata = []
                    pdata.push(response.data)
                    this.setState({
                        allPurchaseData: pdata
                    })
                    if (pdata.length > 0) {
                        let NoRCPurchase = pdata.filter(row => row.rcId === null)
                        this.setState({
                            purchaseData: NoRCPurchase
                        })

                        var arr = []
                        var bikeArr = []
                        arr.push({ id: 0, name: 'purchaseNumber', label: '', value: '' })
                        bikeArr.push({ id: 0, name: 'bikeNumber', label: '', value: '' })
                        pdata.map((item, key) => {
                            arr.push({ id: item.id, name: 'purchaseNumber', label: item.purchaseNumber, value: item.purchaseNumber })
                            bikeArr.push({ id: item.id, name: 'bikeNumber', label: item.bikeNumber, value: item.bikeNumber })
                        });
                        this.setState({ purchaseNumOptions: arr, bikeNumberOptions: bikeArr })
                    }
                    if (window.localStorage.getItem('openRCFrom') !== null && (window.localStorage.getItem('openRCFrom') === 'purchase' || window.localStorage.getItem('openRCFrom') === 'purchasepage')) {
                        // this.setState({
                        //     purchaseData: JSON.parse(window.localStorage.getItem('RCdata'))
                        // })
                        let id = window.localStorage.getItem('editRCId')

                        if (id > 0) {
                            this.fillEditRCData()
                        } else {
                            this.state.purchaseData.push(JSON.parse(window.localStorage.getItem('RCdata')))
                            this.setEditData(JSON.parse(window.localStorage.getItem('RCdata')), "purchase");
                        }

                    } else {
                        this.fillEditRCData()
                    }
                    this.setState({ loadingIsOpen: false })
                }
            )
        } else {
            HttpAPI.getPurchase().then(
                response => {
                    var response = response.data;
                    this.setState({
                        allPurchaseData: response.data
                    })
                    if (response.data.length > 0) {
                        let NoRCPurchase = response.data.filter(row => row.rcId === null)
                        this.setState({
                            purchaseData: NoRCPurchase
                        })
                        var arr = []
                        var bikeArr = []
                        arr.push({ id: 0, name: 'purchaseNumber', label: '', value: '' })
                        bikeArr.push({ id: 0, name: 'bikeNumber', label: '', value: '' })
                        NoRCPurchase.map((item, key) => {
                            arr.push({ id: item.id, name: 'purchaseNumber', label: item.purchaseNumber, value: item.purchaseNumber })
                            bikeArr.push({ id: item.id, name: 'bikeNumber', label: item.bikeNumber, value: item.bikeNumber })
                        });
                        this.setState({ purchaseNumOptions: arr, bikeNumberOptions: bikeArr })
                    }
                    if (window.localStorage.getItem('openRCFrom') !== null && window.localStorage.getItem('openRCFrom') === 'purchase') {
                        // this.setState({
                        //     purchaseData: JSON.parse(window.localStorage.getItem('RCdata'))
                        // })
                        let id = window.localStorage.getItem('editRCId')

                        if (id > 0) {
                            this.fillEditRCData()
                        } else {
                            console.log(JSON.parse(window.localStorage.getItem('RCdata')))
                            this.state.purchaseData.push(JSON.parse(window.localStorage.getItem('RCdata')))
                            this.setEditData(JSON.parse(window.localStorage.getItem('RCdata')), "purchase");
                        }

                    } else {
                        this.fillEditRCData()
                    }
                    this.setState({ loadingIsOpen: false })
                }
            );
        }
    }
    fillEditRCData() {

        let id = window.localStorage.getItem('editRCId')
        if (id > 0) {
            HttpAPI.getRCById(id).then(
                response => {
                    var response = response.data;
                    if (response.status === true) {
                        this.setEditData(response.data, "");
                    }
                }
            );
        }
    }
    closeAPIResponse = () => {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }

    setInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    changeinputvar = (e) => {
        this.setState({
            [e.target.name]: e.target.checked
        })
    }

    changeSelectVar = (e) => {
        if (e.name === 'customer') {
            let id = e.id;
            this.setState({
                [e.name + 'Id']: e.id,
                [e.name + 'Name']: e.value,
                ['selected' + e.name]: e
            });
            if (id > 0) {
                let filterRow = this.state.contactData.filter(row => row.id === Number(id))[0]
                this.setState({ mobileNumber: filterRow.mobileNumber })
            }
        } else {
            this.setState({
                [e.name + 'Id']: e.id,
                [e.name + 'Name']: e.value,
                ['selected' + e.name]: e
            });
        }
    }

    handleDateChange = (e) => {
        this.setState({ startdate: e });
    }


    validateData = () => {
        let map = {
            'purchaseNumber': { 'valid': true, 'error': '' },
            'rcBookId': { 'valid': true, 'error': '' },
            'stock': { 'valid': true, 'error': '' },
            'customer': { 'valid': true, 'error': '' },
            'company': { 'valid': true, 'error': '' },
            'model': { 'valid': true, 'error': '' },
            'rcRecvdDate': { 'valid': true, 'error': '' },
            'bikeNumber': { 'valid': true, 'error': '' },
            'engineNumber': { 'valid': true, 'error': '' },
            'chasisNumber': { 'valid': true, 'error': '' },
            'rtoAddress': { 'valid': true, 'error': '' },
        }
        let isValid = true;
        if (this.state.purchaseNumber === '') {
            //this.setState({ apiResponseMsg: 'Please Fill Purchase No' })
            map.purchaseNumber.valid = false;
            map.purchaseNumber.error = 'Please Fill Purchase No'
            this.purchaseNumInput.current.focus()
            isValid = false
        }
        if (this.state.customerName === '' || this.state.customerName === 'select customer') {
            //this.setState({ apiResponseMsg: 'Please select customer' })
            map.customer.valid = false;
            map.customer.error = 'Please select customer'
            if (isValid === true) {
                this.customerInput.current.focus()
            }
            isValid = false
        }
        if (this.state.companyName === '' || this.state.companyName === 'select company') {
            //this.setState({ apiResponseMsg: 'Please select customer' })
            map.company.valid = false;
            map.company.error = 'Please select company'
            if (isValid === true) {
                this.companyInput.current.focus()
            }
            isValid = false
        }
        if (this.state.modelName === '' || this.state.modelName === 'select model') {
            //this.setState({ apiResponseMsg: 'Please select model' })
            map.model.valid = false;
            map.model.error = 'Please select model'
            if (isValid === true) {
                this.modelInput.current.focus()
            }
            isValid = false
        }

        if (this.state.bikeNumber === '') {
            //this.setState({ apiResponseMsg: 'Please fill Bike Number' })
            map.bikeNumber.valid = false;
            map.bikeNumber.error = 'Please fill Bike Number'
            if (isValid === true) {
                this.bikeNumberInput.current.focus()
            }
            isValid = false
        }
        // else if (ApiUtils.ValidateBikeNo(this.state.bikeNumber) === false) {
        //     //this.setState({ apiResponseMsg: 'Please fill valid Bike No' })
        //     map.bikeNumber.valid = false;
        //     map.bikeNumber.error = 'Please fill valid Bike No'
        //     if (isValid === true) {
        //         this.bikeNumberInput.current.focus()
        //     }
        //     isValid = false
        // }
        // if (this.state.engineNumber === '') {
        //     //this.setState({ apiResponseMsg: 'Please fill Engine Number' })
        //     map.engineNumber.valid = false;
        //     map.engineNumber.error = 'Please fill Engine No'
        //     if (isValid === true) {
        //         this.engineNumberInput.current.focus()
        //     }
        //     isValid = false
        // } else if (ApiUtils.validateEngineNo(this.state.engineNumber) === false) {
        //     //this.setState({ apiResponseMsg: 'Please fill valid Engine No' })
        //     map.engineNumber.valid = false;
        //     map.engineNumber.error = 'Please fill valid Engine No'
        //     if (isValid === true) {
        //         this.engineNumberInput.current.focus()
        //     }
        //     isValid = false
        // }
        // if (this.state.chasisNumber === '') {
        //     //this.setState({ apiResponseMsg: 'Please fill Chasis Number' })
        //     map.chasisNumber.valid = false;
        //     map.chasisNumber.error = 'Please fill Chasis No'
        //     if (isValid === true) {
        //         this.chasisNumberInput.current.focus()
        //     }
        //     isValid = false
        // } else if (ApiUtils.validateChasisNo(this.state.chasisNumber) === false) {
        //     //this.setState({ apiResponseMsg: 'Please fill valid Chasis No' })
        //     map.chasisNumber.valid = false;
        //     map.chasisNumber.error = 'Please fill valid Chasis No'
        //     if (isValid === true) {
        //         this.chasisNumberInput.current.focus()
        //     }
        //     isValid = false
        // }

        if (this.state.rcBookId === '') {
            //this.setState({ apiResponseMsg: 'Please Fill RC Book ID' })
            map.rcBookId.valid = false;
            map.rcBookId.error = 'Please Fill RC Book No'
            if (isValid === true) {
                this.rcBookIdInput.current.focus()
            }
            isValid = false
        }
        if (this.state.stock === '') {
            //this.setState({ apiResponseMsg: 'Please Fill Stock' })
            map.stock.valid = false;
            map.stock.error = 'Please Fill Stock'
            if (isValid === true) {
                this.stockInput.current.focus()
            }
            isValid = false
        }



        if (this.state.rcRecvdDate === null) {
            //this.setState({ apiResponseMsg: 'Please select RC Received Date' })
            map.rcRecvdDate.valid = false;
            map.rcRecvdDate.error = 'Please select RC Received Date'
            if (isValid === true) {
                this.rcRecvdDateInput.current.focus()
            }
            isValid = false
        }
        this.setState({ errorMap: map })
        return isValid;
    }
    isAllDocReceived = () => {
        if (this.state.noc === true && this.state.form352Copy === true) {
            return true
        }
        return false
    }
    getTransComplete = () => {
        if (this.state.isFinance === true) {
            if (this.isAllDocReceived() === true & this.state.kyc === true) {
                return true;
            } else {
                return false;
            }
        } else {
            if (this.state.kyc === true && (this.state.purchaseRecord.paidAmount === this.state.purchaseRecord.purchaseAmount)) {
                return true;
            } else {
                return false;
            }
        }
    }
    createRC = () => {
        let data = {
            'purchaseNumber': this.state.purchaseNumber,
            'rcBookId': this.state.rcBookId,
            'stock': this.state.stock,
            'rcRecvdDate': this.state.startdate,
        };
        this.setState({ loadingIsOpen: true })
        let senddata = ApiUtils.appendCreateRequiredField(data);
        HttpAPI.createRC(senddata).then(
            response => {
                var response = response.data;
                if (response.status === true) {

                    if (window.localStorage.getItem('openRCFrom') === '') {
                        this.state.purchaseRecord.rcId = response.data.id
                        this.state.purchaseRecord.rcBookReceived = true
                        this.state.purchaseRecord.form35 = this.state.form351Copy
                        this.state.purchaseRecord.form352Copy = this.state.form352Copy
                        this.state.purchaseRecord.kycDocs = this.state.kyc
                        this.state.purchaseRecord.noc = this.state.noc
                        this.state.purchaseRecord.parsingDate = this.state.parsingDt
                        this.state.purchaseRecord.rtoDetail = this.state.rtoAddress
                        this.state.purchaseRecord.allDocsReceived = this.isAllDocReceived()
                        this.state.purchaseRecord.transactionComplete = this.getTransComplete()
                        HttpAPI.updatePurchase(this.state.purchaseRecord).then(purResponse => {
                            this.clearData();
                            this.renderDataFromServer();
                        })

                    } else {
                        let purchaseDataRecord = JSON.parse(window.localStorage.getItem('RCdata'))
                        purchaseDataRecord.rcBookReceived = true
                        purchaseDataRecord.form35 = this.state.form351Copy
                        purchaseDataRecord.form352Copy = this.state.form352Copy
                        purchaseDataRecord.kycDocs = this.state.kyc
                        purchaseDataRecord.noc = this.state.noc
                        purchaseDataRecord.parsingDate = this.state.parsingDt
                        purchaseDataRecord.rtoDetail = this.state.rtoAddress
                        purchaseDataRecord.rcId = response.data.id
                        purchaseDataRecord.rtoDetail = this.state.rtoAddress
                        purchaseDataRecord.allDocsReceived = this.isAllDocReceived()
                        purchaseDataRecord.transactionComplete = this.getTransComplete()
                        if (purchaseDataRecord.id > 0) {
                            HttpAPI.updatePurchase(purchaseDataRecord).then(purResponse => {
                                window.localStorage.setItem('RCdata', JSON.stringify(purchaseDataRecord))
                                window.localStorage.setItem('openRCFromId', response.data.id)
                                window.localStorage.setItem('editRCId', 0)
                                window.localStorage.setItem('openRCFrom', '')
                                //window.localStorage.setItem('RCdata', '')
                                window.location.href = window.localStorage.getItem('redirectTo')
                            })
                        } else {
                            window.localStorage.setItem('RCdata', JSON.stringify(purchaseDataRecord))
                            window.localStorage.setItem('openRCFromId', response.data.id)
                            window.localStorage.setItem('editRCId', 0)
                            window.localStorage.setItem('openRCFrom', '')
                            //window.localStorage.setItem('RCdata', '')
                            window.location.href = window.localStorage.getItem('redirectTo')
                        }

                    }
                    this.setState({
                        APILoader: false,
                        apiResponse: true,
                        apiResponseMsg: 'RC added Successfully..',
                        apiResponseModalOpen: true
                    })


                } else {
                    this.setState({
                        APILoader: false,
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.closeAPIResponse();
                }, 1000)
            }
        );
    }
    UpdateRC = () => {
        let data = {
            'id': this.state.rcId,
            'purchaseNumber': this.state.purchaseNumber,
            'rcBookId': this.state.rcBookId,
            'stock': this.state.stock,
            'rcRecvdDate': this.state.startdate,
            'submitted':this.state.editrcdata.submitted,
            'active': this.state.editrcdata.active,
            'createdAt': this.state.editrcdata.createdAt,
            'createdBy': this.state.editrcdata.createdBy,
        }


        let senddata = ApiUtils.appendUpdateRequiredField(data);
        this.setState({ APILoader: true })
        HttpAPI.UpdateRC(senddata).then(
            response => {
                var response = response.data;
                if (response.status === true) {
                    this.state.purchaseRecord.rcId = response.data.id
                    this.state.purchaseRecord.rcBookReceived = true
                    this.state.purchaseRecord.form35 = this.state.form351Copy
                    this.state.purchaseRecord.form352Copy = this.state.form352Copy
                    this.state.purchaseRecord.kycDocs = this.state.kyc
                    this.state.purchaseRecord.noc = this.state.noc
                    this.state.purchaseRecord.parsingDate = this.state.parsingDt
                    this.state.purchaseRecord.rtoDetail = this.state.rtoAddress
                    this.state.purchaseRecord.allDocsReceived = this.isAllDocReceived()
                    this.state.purchaseRecord.transactionComplete = this.getTransComplete()
                    HttpAPI.updatePurchase(this.state.purchaseRecord).then(res => {
                        var res = res.data
                        if (window.localStorage.getItem('openRCFrom') === 'purchase') {
                            window.localStorage.setItem('editRCId', 0)
                            window.localStorage.setItem('openRCFrom', '')
                            window.localStorage.setItem('RCdata', this.state.purchaseRecord)
                            //window.history.go(-1);
                            window.location.href = window.localStorage.getItem('redirectTo')
                        } else {
                            this.clearData();
                            this.renderDataFromServer();
                        }
                        this.setState({
                            APILoader: false,
                            APILoader: false,
                            apiResponse: true,
                            apiResponseMsg: 'RC Update Successfully',
                            apiResponseModalOpen: true
                        })
                    })
                } else {
                    this.setState({
                        APILoader: false,
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.closeAPIResponse();
                }, 2000)
            }
        );
    }
    closeRCAddForm = () => {
        this.clearData();
        if (window.localStorage.getItem('openRCFrom') === 'purchase') {
            window.location.href = window.localStorage.getItem('redirectTo')
        } else {
            window.location.href = '/rc'
        }
        this.setState({ purchasRCId: 0 })
    }
    setEditData = (editrcdata, openFrom) => {

        const data = this.state.modelData.filter(row => row.companyId === editrcdata.companyId);
        this.setState({
            filterModel: data
        });

        if (openFrom === 'purchase') {
            let id = window.localStorage.getItem('editRCId')
            if (id > 0) {

                var purchaseRow = this.state.allPurchaseData.filter(row => row.rcId === Number(id))[0]

                this.setState({
                    purchaseRecord: purchaseRow,
                    purchaseNumber: purchaseRow.purchaseNumber,
                    companyName: purchaseRow.companyMST.description,
                    modelName: purchaseRow.modelMST.description,
                    bikeNumber: purchaseRow.bikeNumber,
                    engineNumber: purchaseRow.engineNumber,
                    chasisNumber: purchaseRow.chasisNumber,
                    isFinance: purchaseRow.financed,
                    disableDocReceived:!editrcdata.financed,
                    customerName: purchaseRow.contactMST.firstName,
                    mobileNumber: purchaseRow.contactMST.mobileNumber,
                    rtoAddress: purchaseRow.rtoDetail,
                    form351Copy: purchaseRow.form35,
                    form352Copy: purchaseRow.form352Copy,
                    kyc: purchaseRow.kycDocs,
                    noc: purchaseRow.noc,
                    parsingDt: purchaseRow.parsingDate === null ? null : new Date(purchaseRow.parsingDate),
                })
                if (purchaseRow.form35 === true) {
                    this.setState({ selectedForm35Copy: '1' })
                } else if (purchaseRow.form352Copy === true) {
                    this.setState({ selectedForm35Copy: '2' })
                } else {
                    this.setState({ selectedForm35Copy: '' })
                }

                this.setState({
                    selectedpurchaseNumber: { id: 0, name: 'purchaseNumber', label: purchaseRow.purchaseNumber, value: purchaseRow.purchaseNumber },
                    selectedbikeNumber: { id: 0, name: 'bikeNumber', label: purchaseRow.bikeNumber, value: purchaseRow.bikeNumber },

                })
                this.setState({
                    rcBookId: editrcdata.rcBookId,
                    stock: editrcdata.stock,
                    startdate: editrcdata.rcRecvdDate===null?null: new Date(editrcdata.rcRecvdDate),
                    isSubmittedRc:editrcdata.submitted,
                })
                if(editrcdata.submitted===true){
                    this.setState({
                        disableDocReceived:true
                    })
                }

            } else {
                this.setState({
                    purchaseRecord: editrcdata,
                    purchaseNumber: editrcdata.purchaseNumber,
                    companyName: editrcdata.companyMST.description,
                    modelName: editrcdata.modelMST.description,
                    bikeNumber: editrcdata.bikeNumber,
                    engineNumber: editrcdata.engineNumber,
                    chasisNumber: editrcdata.chasisNumber,
                    isFinance: editrcdata.financed,
                    disableDocReceived:!editrcdata.financed,
                    customerName: editrcdata.contactMST.firstName,
                    mobileNumber: editrcdata.contactMST.mobileNumber,
                    rtoAddress: editrcdata.rtoDetail,
                    form351Copy: editrcdata.form35,
                    form352Copy: editrcdata.form352Copy,
                    kyc: editrcdata.kycDocs,
                    noc: editrcdata.noc,
                    parsingDt: editrcdata.parsingDate === null ? null : new Date(editrcdata.parsingDate),
                })
                if (editrcdata.form35 === true) {
                    this.setState({ selectedForm35Copy: '1' })
                } else if (editrcdata.form352Copy === true) {
                    this.setState({ selectedForm35Copy: '2' })
                } else {
                    this.setState({ selectedForm35Copy: '' })
                }
                this.setState({
                    selectedpurchaseNumber: { id: 0, name: 'purchaseNumber', label: editrcdata.purchaseNumber, value: editrcdata.purchaseNumber },
                    selectedbikeNumber: { id: 0, name: 'bikeNumber', label: editrcdata.bikeNumber, value: editrcdata.bikeNumber },
                })

            }
        } else {

            this.setState({ editrcdata: editrcdata })
            this.setState({ rcId: editrcdata.id })

            let purchaseRow = this.state.allPurchaseData.filter(row => row.rcId === editrcdata.id)[0]
            this.setState({
                purchaseRecord: purchaseRow,

                companyName: purchaseRow.companyMST.description,
                modelName: purchaseRow.modelMST.description,

                customerName: purchaseRow.contactMST.firstName,
                mobileNumber: purchaseRow.contactMST.mobileNumber,
                bikeNumber: purchaseRow.bikeNumber,
                engineNumber: purchaseRow.engineNumber,
                isFinance: purchaseRow.financed,
                disableDocReceived:!purchaseRow.financed,
                chasisNumber: purchaseRow.chasisNumber,
                rtoAddress: purchaseRow.rtoAddress,
                allDocReceived: purchaseRow.allDocsReceived,
                rtoAddress: purchaseRow.rtoDetail,
                form351Copy: purchaseRow.form35,
                form352Copy: purchaseRow.form352Copy,
                kyc: purchaseRow.kycDocs,
                noc: purchaseRow.noc,
                parsingDt: purchaseRow.parsingDate === null ? null : new Date(purchaseRow.parsingDate),
            })
            if (purchaseRow.form35 === true) {
                this.setState({ selectedForm35Copy: '1' })
            } else if (purchaseRow.form352Copy === true) {
                this.setState({ selectedForm35Copy: '2' })
            } else {
                this.setState({ selectedForm35Copy: '' })
            }
            this.setState({
                id: editrcdata.id,
                purchaseNumber: editrcdata.purchaseNumber,
                rcBookId: editrcdata.rcBookId,
                stock: editrcdata.stock,
                startdate:editrcdata.rcRecvdDate===null?null: new Date(editrcdata.rcRecvdDate),
                isSubmittedRc:editrcdata.submitted,
            })
            if(editrcdata.submitted===true){
                this.setState({
                    disableDocReceived:true
                })
            }


            this.setState({
                selectedpurchaseNumber: { id: purchaseRow.id, name: 'purchaseNumber', label: purchaseRow.purchaseNumber, value: purchaseRow.purchaseNumber },
                selectedbikeNumber: { id: purchaseRow.id, name: 'bikeNumber', label: purchaseRow.bikeNumber, value: purchaseRow.bikeNumber },
            })

        }
        this.setState({ isPurchaseDis: true })
    }
    setBikeNo = (e) => {
        let purchaseRow = this.state.purchaseData.filter(row => row.bikeNumber !== null && row.bikeNumber.toLowerCase() === e.target.value.toLowerCase())
        if (purchaseRow.length > 0) {
            let bikeNumberRow = purchaseRow[0]
            this.setState({
                companyName: bikeNumberRow.companyMST.description,
                modelName: bikeNumberRow.modelMST.description,
                bikeNumber: bikeNumberRow.bikeNumber,
                engineNumber: bikeNumberRow.engineNumber,
                chasisNumber: bikeNumberRow.chasisNumber,
                customerName: bikeNumberRow.contactMST.firstName,
                mobileNumber: bikeNumberRow.contactMST.mobileNumber,
                rtoAddress: bikeNumberRow.rtoDetail,
                form351Copy: bikeNumberRow.form35,
                form352Copy: bikeNumberRow.form352Copy,
                kyc: bikeNumberRow.kyc,
                noc: bikeNumberRow.noc,
                parsingDt: new Date(bikeNumberRow.parsingDate),
            })
            if (bikeNumberRow.form35 === true) {
                this.setState({ selectedForm35Copy: '1' })
            } else if (bikeNumberRow.form352Copy === true) {
                this.setState({ selectedForm35Copy: '2' })
            } else {
                this.setState({ selectedForm35Copy: '' })
            }
            this.setState({
                selectedpurchaseNumber: [{ id: bikeNumberRow.id, name: 'purchaseNumber', label: bikeNumberRow.purchaseNumber, value: bikeNumberRow.purchaseNumber }],

            })

        } else {
            this.setState({
                companyName: '',
                modelName: '',
                engineNumber: '',
                chasisNumber: '',
                customerName: '',
                mobileNumber: '',
                rtoAddress: '',
                form351Copy: '',
                form352Copy: '',
                kyc: '',
                noc: '',
                parsingDt: null,
                selectedForm35Copy: '',
                selectedpurchaseNumber: ''
            })
        }
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    setSelectInputVar = (e) => {
        this.setState({
            ['selected' + e.name]: e,
            [e.name]: e.value
        });
    }

    checkBoxChange = (e) => {
        this.setState({ [e.target.name]: e.target.checked });
        if(e.target.name==='isFinance'){
            this.setState({disableDocReceived:!e.target.checked})
        }
    }
    changeCompanyVar = (e) => {
        var filterId = Number(e.id);
        const data = this.state.modelData.filter(row => row.companyId === filterId);
        var arr = []
        arr.push({ id: 0, name: 'model', label: '', value: '' })
        data.map(item => {
            arr.push({ id: item.id, name: 'model', label: item.description, value: item.description })
        })
        this.setState({
            selectedmodel: '',
            modelOptions: arr,
        })
        this.changeSelectVar(e);
    }
    changePurchaseNo = (e) => {
        var filterId = Number(e.id);
        this.autoPopulatePurchaseData(filterId)


    }
    autoPopulatePurchaseData = (filterId) => {
        if (filterId > 0) {
            const data = this.state.purchaseData.filter(row => row.id === filterId);
            let purchaseRecord = data[0]
            const filterModelData = this.state.modelData.filter(row => row.companyId === purchaseRecord.companyId);
            this.setState({
                filterModel: filterModelData,
                purchaseRecord: purchaseRecord,
                purchaseNumber: purchaseRecord.purchaseNumber,
                companyName: purchaseRecord.companyMST.description,
                modelName: purchaseRecord.modelMST.description,
                bikeNumber: purchaseRecord.bikeNumber,
                engineNumber: purchaseRecord.engineNumber,
                chasisNumber: purchaseRecord.chasisNumber,
                customerName: purchaseRecord.contactMST.firstName,
                mobileNumber: purchaseRecord.contactMST.mobileNumber,
                rtoAddress: purchaseRecord.rtoDetail,
                form351Copy: purchaseRecord.form35,
                form352Copy: purchaseRecord.form352Copy,
                kyc: purchaseRecord.kycDocs,
                noc: purchaseRecord.noc,
                isFinance: purchaseRecord.financed,
                disableDocReceived:!purchaseRecord.financed,
                parsingDt: purchaseRecord.parsingDate === null ? null : new Date(purchaseRecord.parsingDate),
            })
            if (purchaseRecord.form35 === true) {
                this.setState({ selectedForm35Copy: '1' })
            } else if (purchaseRecord.form352Copy === true) {
                this.setState({ selectedForm35Copy: '2' })
            } else {
                this.setState({ selectedForm35Copy: '' })
            }
            this.setState({
                selectedpurchaseNumber: { id: purchaseRecord.id, name: 'purchaseNumber', label: purchaseRecord.purchaseNumber, value: purchaseRecord.purchaseNumber },
                selectedbikeNumber: { id: purchaseRecord.id, name: 'bikeNumber', label: purchaseRecord.bikeNumber, value: purchaseRecord.bikeNumber }
            });
        } else {
            this.setState({
                filterModel: '',
                purchaseRecord: {},
                purchaseNumber: '',
                companyName: '',
                modelName: '',
                bikeNumber: '',
                engineNumber: '',
                chasisNumber: '',
                customerName: '',
                mobileNumber: '',
                rtoAddress: '',
                form351Copy: false,
                form352Copy: false,
                kyc: false,
                noc: false,
                isFinance: false,
                parsingDt: null,
                selectedForm35Copy: '',
                selectedbikeNumber: { id: 0, name: 'bikeNumber', label: '', value: '' },
                selectedpurchaseNumber: { id: 0, name: 'purchaseNumber', label: '', value: '' },
            })
        }
    }
    changeBikeNo = (e) => {
        var filterId = Number(e.id);
        this.autoPopulatePurchaseData(filterId)
    }
    saveData = () => {
        if (this.validateData() === true) {
            if (this.state.rcId === 0) {
                this.createRC();
            } else {
                this.UpdateRC();
            }
        }
    }
    isAllDocReceived = () => {
        if (this.state.kyc === true && this.state.noc === true && this.state.form352Copy === true) {
            return true
        }
        return false
    }
    changeFormRadio = (e) => {
        if (e.target.value === '1') {
            if (this.state.form351Copy === true) {
                this.setState({ form351Copy: false, form352Copy: false })
                this.setState({ selectedForm35Copy: '' })
            } else {
                this.setState({ form351Copy: true, form352Copy: false })
                this.setState({ selectedForm35Copy: e.target.value })
            }

        } else {
            if (this.state.form352Copy === true) {
                this.setState({ form351Copy: false, form352Copy: false })
                this.setState({ selectedForm35Copy: '' })
            } else {
                this.setState({ form351Copy: false, form352Copy: true })
                this.setState({ selectedForm35Copy: e.target.value })
            }

        }
    }
    handleParsingDateChange = (e) => {
        this.setState({ parsingDt: e })

    }
    clearData = () => {
        window.localStorage.setItem('editRCId', 0)
        window.localStorage.setItem('openRCFrom', '')
        window.localStorage.setItem('RCdata', '')
        this.setState({
            startdate: new Date(),
            parsingDt: null,
            purchaseNumber: '',
            rcBookId: '',
            stock: '',
            company: '',
            model: '',
            customerName: '',
            loandetail: '',
            bikeNumber: '',
            engineNumber: '',
            chasisNumber: '',
            rtoAddress: '',
            companyData: [],
            modelData: [],
            companyName: '',
            modelName: '',
            filterModel: [],
            rcdata: [],
            smsValue: false,
            purchaseRecord: {},
            id: 0,
            rcId: 0,
            mobileNumber: '',
            allDocReceived: false,
            idProofReceived: false,
            purchaseData: [],
            isPurchaseDis: false,
            isSubmittedRc:false,
            disableDocReceived:false,
            selectedcompany: '',
            selectedpurchaseNumber: '',
            selectedbikeNumber: '',
            purchaseNumOptions: [{ id: 0, name: 'purchaseNumber', label: "", value: "" }],
            bikeNumberOptions: [{ id: 0, name: 'bikeNumber', label: "", value: "" }],
            errorMap: {
                'purchaseNumber': { 'valid': true, 'error': '' },
                'rcBookId': { 'valid': true, 'error': '' },
                'stock': { 'valid': true, 'error': '' },
                'customer': { 'valid': true, 'error': '' },
                'company': { 'valid': true, 'error': '' },
                'model': { 'valid': true, 'error': '' },
                'rcRecvdDate': { 'valid': true, 'error': '' },
                'bikeNumber': { 'valid': true, 'error': '' },
                'engineNumber': { 'valid': true, 'error': '' },
                'chasisNumber': { 'valid': true, 'error': '' },
                'rtoAddress': { 'valid': true, 'error': '' },
            },
        })
    }
    render() {
        return (
            <div>
                <MDBRow className='justify-content-left ml-0'>
                    <MDBCol sm="6">

                    </MDBCol>

                    <MDBCol sm="3">

                    </MDBCol>
                    <MDBCol sm="3">
                        <div className="CustomBreadCrumbaddRC">
                            <CustomBreadCrumb items={['Purchase', 'RC', this.state.breadCrumbLabel]} linkTo={['', '/rc', '']} />
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className='justify-content-left ml-0'>
                    <MDBCol lg="12">
                        <MDBCard className="">
                            <MDBCardBody className="align-items-center justify-content-between">
                                <form>
                                    <div className="row">
                                        <div className="form-group col-xl-2 col-lg-2 col-xs-12 col-sm-12 col-md-3">
                                            <label for="purchaseNo" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Purchase No</span><span style={{ color: 'red' }}> *</span></label>
                                            <Select isDisabled={this.state.isPurchaseDis} ref={this.purchaseNumInput} options={this.state.purchaseNumOptions} name='purchaseNumber' value={this.state.selectedpurchaseNumber} onChange={this.changePurchaseNo} id="select" class="input-field browser-default md-select form-control">

                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.purchaseNumber.valid == true ? '' : this.state.errorMap.purchaseNumber.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-xs-12 col-sm-12 col-md-3">
                                            <label for="bikeselect" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Bike No</span><span style={{ color: 'red' }}> *</span></label>
                                            <Select isDisabled={this.state.isPurchaseDis} ref={this.bikeNumberInput} options={this.state.bikeNumberOptions} name='bikeNumber' value={this.state.selectedbikeNumber} onChange={this.changeBikeNo} id="bikeselect" class="input-field browser-default md-select form-control">
                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.bikeNumber.valid == true ? '' : this.state.errorMap.bikeNumber.error}</span>
                                        </div>
                                        {/*
                                        <div className="form-group col-sm-2">
                                            <label for="Bikeno" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Bike No</label>
                                            <input disabled={this.state.isPurchaseDis} type="text" ref={this.bikeNumberInput} name="bikeNumber" value={this.state.bikeNumber} className="form-control form-text font-small m-0 p-0" onChange={this.setBikeNo} id="Bikeno">
                                            </input>
                                            <span className='error-message'>{this.state.errorMap.bikeNumber.valid == true ? '' : this.state.errorMap.bikeNumber.error}</span>
                                        </div>
                                        */}
                                        <div className="form-group col-xl-2 col-lg-2 col-xs-12 col-sm-12 col-md-3">
                                            <label for="companyId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Company </span><span style={{ color: 'red' }}> *</span></label>
                                            <input type="text" disabled name="companyName" value={this.state.companyName} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar} id="companyId">
                                            </input>
                                            <span className='error-message'>{this.state.errorMap.company.valid == true ? '' : this.state.errorMap.company.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-xs-12 col-sm-12 col-md-3">
                                            <label for="modelId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Model </span><span style={{ color: 'red' }}> *</span></label>
                                            <input type="text" disabled name="modelName" value={this.state.modelName} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar} id="modelId">
                                            </input>
                                            <span className='error-message'>{this.state.errorMap.model.valid == true ? '' : this.state.errorMap.model.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-xs-12 col-sm-12 col-md-3">
                                            <label for="EngineNo" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Engine No</label>
                                            <input disabled type="text" ref={this.engineNumberInput} name="engineNumber" value={this.state.engineNumber} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar} id="EngineNo">
                                            </input>
                                            <span className='error-message'>{this.state.errorMap.engineNumber.valid == true ? '' : this.state.errorMap.engineNumber.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-xs-12 col-sm-12 col-md-3">
                                            <label for="chasisno" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Chasis No</label>
                                            <input disabled type="text" ref={this.chasisNumberInput} name="chasisNumber" value={this.state.chasisNumber} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar} id="chasisno">
                                            </input>
                                            <span className='error-message'>{this.state.errorMap.chasisNumber.valid == true ? '' : this.state.errorMap.chasisNumber.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-xs-12 col-sm-12 col-md-3">
                                            <label for="rcbookno" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Rc Book No </span><span style={{ color: 'red' }}> *</span></label>
                                            <input type="text" disabled={this.state.isSubmittedRc} name="rcBookId" ref={this.rcBookIdInput} value={this.state.rcBookId} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar} id="rcbookno">
                                            </input>
                                            <span className='error-message'>{this.state.errorMap.rcBookId.valid == true ? '' : this.state.errorMap.rcBookId.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-xs-12 col-sm-12 col-md-3">
                                            <label for="stock" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Stock </span><span style={{ color: 'red' }}> *</span></label>
                                            <input type="text" name="stock" ref={this.stockInput} value={this.state.stock} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar} id="stock">
                                            </input>
                                            <span className='error-message'>{this.state.errorMap.stock.valid == true ? '' : this.state.errorMap.stock.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-xs-12 col-sm-12 col-md-3">
                                            <label for="Parsing Dt" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >Passing Date</label>
                                            <DatePicker
                                                selected={this.state.parsingDt}
                                                onChange={this.handleParsingDateChange}
                                                dateFormat="dd-MMM-yyyy"
                                                placeholderText='Passing Dt'
                                                className="form-control"
                                                disabled={this.state.isSubmittedRc}
                                                isClearable />
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-xs-12 col-sm-12 col-md-3">
                                            <label for="rtoaddress" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >RTO Address</label>
                                            <input type="text" disabled={this.state.isSubmittedRc} ref={this.rtoAddressInput} name="rtoAddress" value={this.state.rtoAddress} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar} id="rtoaddress">
                                            </input>
                                            <span className='error-message'>{this.state.errorMap.rtoAddress.valid == true ? '' : this.state.errorMap.rtoAddress.error}</span>
                                        </div>
                                        <div className="col-xl-2 col-lg-2 col-xs-12 col-sm-12 col-md-3">
                                            <label for="stock" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Received Date</label>
                                            <DatePicker
                                                selected={this.state.startdate}
                                                onChange={this.handleDateChange}
                                                className="form-control"
                                                dateFormat="dd-MMM-yyyy"
                                                ref={this.rcRecvdDateInput}
                                                disabled={this.state.isSubmittedRc}
                                                isClearable />
                                            <span className='error-message'>{this.state.errorMap.rcRecvdDate.valid == true ? '' : this.state.errorMap.rcRecvdDate.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-xs-12 col-sm-12 col-md-3 col-md-3">
                                            <label for="customerId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Customer Name </span><span style={{ color: 'red' }}> *</span></label>
                                            <input type="text" disabled name="customerName" value={this.state.customerName} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar} id="customerId">
                                            </input>
                                            <span className='error-message'>{this.state.errorMap.customer.valid == true ? '' : this.state.errorMap.customer.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-xs-12 col-sm-12 col-md-3">
                                            <label for="Mobileno" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Mobile No</label>
                                            <input type="tel" disabled name="mobileNumber" value={this.state.mobileNumber} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar} id="Mobileno">
                                            </input>

                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-xs-12 col-sm-12 col-md-3 purchasecheckbox">
                                            <CustomCheckBox disable={true} id="isFinanceCheck" name='isFinance' value='Is Financed' handleCheckChieldElement={this.checkBoxChange} checked={this.state.isFinance} />
                                        </div>
                                        <div className=" col-xl-2 col-lg-2 col-xs-12 col-sm-12 col-md-2   purchasecheckbox ">
                                            <CustomCheckBox id="kycCheck" disable={this.state.isSubmittedRc} name='kyc' value='KYC' handleCheckChieldElement={this.checkBoxChange} checked={this.state.kyc} />
                                        </div>
                                        <div className="col-xl-2 col-lg-2 col-xs-12 col-sm-12 col-md-3 " >
                                            <label for="loanaccountno" className="ml- " style={{ 'font-size': '0.7rem', 'color': 'grey' }}  > Doc's Received</label>
                                            <div  className=" form-group h-70 " style={{ width: '170px', border: '1px solid gray' }}>
                                                <label for="loanaccountno" className="ml-3 font-weight-bold text-left" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >Form 35</label>
                                                <div className="radiochecked">
                                                    <input type="radio" onClick={this.changeFormRadio} disabled={this.state.disableDocReceived} value='1' checked={this.state.selectedForm35Copy === '1'} />
                                                    <label for="copy" className="text-left" style={{ 'font-size': '0.8rem', 'color': 'grey' }}>1 Copy</label>
                                                    <input type="radio" className="ml-2 mt-2" disabled={this.state.disableDocReceived} onClick={this.changeFormRadio} value='2' checked={this.state.selectedForm35Copy === '2'} />
                                                    <label for="copy" className="text-left" style={{ 'font-size': '0.8rem', 'color': 'grey' }}>2 Copy</label>
                                                </div>
                                                <div className="radiochecked " >
                                                    <input type="Checkbox" className="" id="nocId" name='noc' disabled={this.state.disableDocReceived} onChange={this.checkBoxChange} checked={this.state.noc} />
                                                    <label for="copy" className="ml-2" style={{ 'font-size': '0.8rem', 'color': 'grey' }}>NOC</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </form>
                                    <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-xs-12 col-sm-12 col-md-12">
                                        <MDBBtn size='sm' onClick={this.saveData} className="addrcsavedata">
                                            <span className="Savedata">
                                                Save
                                            </span>
                                        </MDBBtn>
                                        <MDBBtn size='sm' onClick={this.closeRCAddForm}  className="addrccanceldata">
                                            <span className="Savedata">
                                                Cancel
                                            </span>
                                        </MDBBtn>
                                        </div> 
                                    </div>   
                                    </MDBCardBody>
                                     </MDBCard>    
                                     <APIResponse
                            isOpen={this.state.apiResponseModalOpen}
                            response={this.state.apiResponse}
                            msg={this.state.apiResponseMsg}
                            closeAPIResponse={this.closeAPIResponse}
                        />  
                           <APILoader isOpen={this.state.loadingIsOpen} />              
                                       
                                       
                                   
                                   
                                
                                
                            
                        
                       
                    </MDBCol>
                </MDBRow>
            </div>

        );
    }
}
export default AddRCModal;