import React, { Component } from "react";
import { MDBRow, MDBFormInline, MDBIcon, MDBInput, MDBCard, MDBTabContent, MDBTabPane, MDBContainer, MDBNavItem, MDBNavLink, MDBNav, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBCardBody, MDBBreadcrumbItem, MDBCol, MDBCollapse } from 'mdbreact';
import CustomBreadCrumb from '../sections/CustomBreadCrumb';
import Select from 'react-select';
import HttpAPI from '../../common/api_calls';
import ApiUtils from '../../common/api_utils';
import APIResponse from '../../common/APIResponse'
import APILoader from '../../common/APILoader'

class Addscrap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transactiontype: '',
            selectedtransactiontype: '',
            scrapsalesoption: [{ name: 'transactiontype', label: '', value: '' }, { name: 'transactiontype', label: 'Purchase', value: 'Purchase' }, { name: 'transactiontype', label: 'Sales', value: 'Sales' }],
            CustomerName: '',
            ItemName: '',
            MobileNumber: '',
            Company: '',
            Model: '',
            TotalAmount: '',
            PaidAmount: '',
            isLoaderOpen: false,
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            ScrapId: 0,
            errorMap: {
                'transactiontype': { 'valid': true, 'error': '' },
                'CustomerName': { 'valid': true, 'error': '' },
                'ItemName': { 'valid': true, 'error': '' },
                'MobileNumber': { 'valid': true, 'error': '' },
                'Company': { 'valid': true, 'error': '' },
                'Model': { 'valid': true, 'error': '' },
                'TotalAmount': { 'valid': true, 'error': '' },
                'PaidAmount': { 'valid': true, 'error': '' },

            },
        }
    }
    setSelectInputVar = (e) => {

        this.setState({
            [e.name]: e.value,
            ['selected' + e.name]: e
        });
    }
    setInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    componentDidMount() {
        this.renderDataFromServer();


    }
    renderDataFromServer() {

    }
    closeAPIResponse = () => {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }
    async createScrap()  {
        this.setState({
            isLoaderOpen:true
        })
        let data = {
            'transactionType': this.state.transactiontype,
            'customerName': this.state.CustomerName,
            'itemName': this.state.ItemName,
            'mobileNumber': this.state.MobileNumber,
            'company': this.state.Company,
            'model': this.state.Model,
            'totalAmount': this.state.TotalAmount,
            'paidAmount': this.state.PaidAmount,
        }
        let senddata = ApiUtils.appendCreateRequiredField(data);
        let scrapData=await HttpAPI.createScrap(senddata)
        let scrapResponse=scrapData.data
        if(scrapResponse.status===true){
            this.setState({
                isLoaderOpen:false,
                apiResponse: true,
                apiResponseMsg: 'Scrap Insert Successfully',
                apiResponseModalOpen: true
            })
            this.clearData();
            this.renderDataFromServer();
        }else{
            this.setState({
                isLoaderOpen:false,
                apiResponse: false,
                apiResponseMsg: scrapResponse.message,
                apiResponseModalOpen: true
            })
        }
        window.setTimeout(() => {
            this.setState({
                apiResponse: false,
                apiResponseMsg: '',
                apiResponseModalOpen: false
            })
        }, 1000)
        
    }
    validateData = (action) => {
        let map = {
            'transactiontype': { 'valid': true, 'error': '' },
            'CustomerName': { 'valid': true, 'error': '' },
            'ItemName': { 'valid': true, 'error': '' },
            'MobileNumber': { 'valid': true, 'error': '' },
            'Company': { 'valid': true, 'error': '' },
            'Model': { 'valid': true, 'error': '' },
            'TotalAmount': { 'valid': true, 'error': '' },
            'PaidAmount': { 'valid': true, 'error': '' },
        }
        let isValid = true;
        if (this.state.transactiontype === '') {
            //this.setState({ apiResponseMsg: 'Please select Purchase for' })
            map.transactiontype.valid = false;
            map.transactiontype.error = 'Please select Transaction Type '
            /*
            if (isValid === true) {
                this.transactiontype.current.focus()
            }
            */
            isValid = false
        }
        if (this.state.CustomerName === '') {
            //this.setState({ apiResponseMsg: 'Please select Purchase for' })
            map.CustomerName.valid = false;
            map.CustomerName.error = 'Please select Customer Name'
            /*
            if (isValid === true) {
                this.transactiontype.current.focus()
            }
            */
            isValid = false
        }
        if (this.state.ItemName === '') {
            //this.setState({ apiResponseMsg: 'Please select Purchase for' })
            map.ItemName.valid = false;
            map.ItemName.error = 'Please select Item Name'
            /*
            if (isValid === true) {
                this.transactiontype.current.focus()
            }
            */
            isValid = false
        }
        if (this.state.MobileNumber === '') {
            //this.setState({ apiResponseMsg: 'Please select Purchase for' })
            map.MobileNumber.valid = false;
            map.MobileNumber.error = 'Please select Mobile Number'
            /*
            if (isValid === true) {
                this.transactiontype.current.focus()
            }
            */
            isValid = false
        }
        if (this.state.Company === '') {
            //this.setState({ apiResponseMsg: 'Please select Purchase for' })
            map.Company.valid = false;
            map.Company.error = 'Please select Company'
            /*
            if (isValid === true) {
                this.transactiontype.current.focus()
            }
            */
            isValid = false
        }
        if (this.state.Model === '') {
            //this.setState({ apiResponseMsg: 'Please select Purchase for' })
            map.Model.valid = false;
            map.Model.error = 'Please select Model'
            /*
            if (isValid === true) {
                this.transactiontype.current.focus()
            }
            */
            isValid = false
        }
        if (this.state.TotalAmount === '') {
            //this.setState({ apiResponseMsg: 'Please select Purchase for' })
            map.TotalAmount.valid = false;
            map.TotalAmount.error = 'Please select Total Amount'
            /*
            if (isValid === true) {
                this.transactiontype.current.focus()
            }
            */
            isValid = false
        }
        if (this.state.PaidAmount === '') {
            
            map.TotalAmount.valid = false;
            map.TotalAmount.error = 'Please select Paid Amount'
            /*
            if (isValid === true) {
                this.transactiontype.current.focus()
            }
            */
           isValid = false
        }
        this.setState({ errorMap: map })
        return isValid;

    }
    SaveData = () => {
        console.log("Button Clicked");
        if (this.validateData('save') === true) {
            if (this.state.scrapId > 0) {
                this.UpdateScrap();
            } else {
                this.createScrap();
            }
        }
    }
    clearData = () => {
        this.setState({
            transactiontype: '',
            CustomerName: '',
            ItemName: '',
            MobileNumber: '',
            Company: '',
            Model: '',
            TotalAmount: '',
            PaidAmount: '',
            selectedtransactiontype:''
        })
    }
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-sm-12  d-flex flex-row-reverse">
                    <CustomBreadCrumb items={['General', 'Scrap','Add Scrap']} linkTo={['', '/scrapPage', '']} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <MDBCard className="mb-6 mr-0 ">
                            <MDBCardBody className="className=align-items-center justify-content-between mr-0">
                                <div className="row">
                                    <div className="col-xl-2 col-xs-12 col-sm-12 col-lg-2 col-md-3 ">
                                        <label for="Scrap" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Transaction Type</span><span style={{color:'red'}}> *</span></label>
                                        <Select name="transactiontype" options={this.state.scrapsalesoption} value={this.state.selectedtransactiontype} onChange={this.setSelectInputVar} id="purchase" class="input-field browser-default md-select form-control form-control-sm" >
                                        </Select>
                                        <span className='error-message'>{this.state.errorMap.transactiontype.valid == true ? '' : this.state.errorMap.transactiontype.error}</span>
                                    </div>
                                    <div className=" form-group col-xl-2 col-xs-12 col-sm-12 col-lg-2 col-md-3">
                                        <label for="Item" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Item Name</span><span style={{color:'red'}}> *</span></label>
                                        <input name="ItemName" value={this.state.ItemName} onChange={this.setInputVar} id="Item" className=" form-control " />
                                        <span className='error-message'>{this.state.errorMap.ItemName.valid == true ? '' : this.state.errorMap.ItemName.error}</span>
                                    </div>
                                    <div className=" form-group col-xl-2 col-xs-12 col-sm-12 col-lg-2 col-md-3">
                                        <label for="CustomerName" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Customer Name</span><span style={{color:'red'}}> *</span></label>
                                        <input name="CustomerName" value={this.state.CustomerName} onChange={this.setInputVar} id="CustomerName" className=" form-control " />
                                        <span className='error-message'>{this.state.errorMap.CustomerName.valid == true ? '' : this.state.errorMap.CustomerName.error}</span>
                                    </div>
                                    <div className=" form-group col-xl-2 col-xs-12 col-sm-12 col-lg-2 col-md-3">
                                        <label for="Mobile" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Mobile Number<span style={{color:'red'}}> *</span></label>
                                        <input type="Number" name="MobileNumber" value={this.state.MobileNumber} onChange={this.setInputVar} id="Mobile" className=" form-control " />
                                        <span className='error-message'>{this.state.errorMap.MobileNumber.valid == true ? '' : this.state.errorMap.MobileNumber.error}</span>
                                    </div>
                                    <div className=" form-group col-xl-2 col-xs-12 col-sm-12 col-lg-2 col-md-3">
                                        <label for="Company" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Company<span style={{color:'red'}}> *</span></label>
                                        <input name="Company" value={this.state.Company} onChange={this.setInputVar} id="Company" className=" form-control" />
                                        <span className='error-message'>{this.state.errorMap.Company.valid == true ? '' : this.state.errorMap.Company.error}</span>

                                    </div>
                                    <div className=" form-group col-xl-2 col-xs-12 col-sm-12 col-lg-2 col-md-3">
                                        <label for="Model" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Model<span style={{color:'red'}}> *</span></label>
                                        <input name="Model" value={this.state.Model} onChange={this.setInputVar} id="Model" className=" form-control " />
                                        <span className='error-message'>{this.state.errorMap.Model.valid == true ? '' : this.state.errorMap.Model.error}</span>
                                    </div>
                                    <div className=" form-group col-xl-2 col-xs-12 col-sm-12 col-lg-2 col-md-3">
                                        <label for="TotalAmount" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Total Amount<span style={{color:'red'}}> *</span></label>
                                        <input name="TotalAmount" value={this.state.TotalAmount} onChange={this.setInputVar} id="TotalAmount" className=" form-control " />
                                        <span className='error-message'>{this.state.errorMap.TotalAmount.valid == true ? '' : this.state.errorMap.TotalAmount.error}</span>
                                    </div>
                                    <div className=" form-group col-xl-2 col-xs-12 col-sm-12 col-lg-2 col-md-3">
                                        <label for="PaidAmount" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Paid Amount<span style={{color:'red'}}> *</span></label>
                                        <input name="PaidAmount" value={this.state.PaidAmount} onChange={this.setInputVar} id="PaidAmount" className=" form-control " />
                                        <span className='error-message'>{this.state.errorMap.TotalAmount.valid == true ? '' : this.state.errorMap.TotalAmount.error}</span>
                                    </div>
                                    <div className="col-xl-1 col-lg-1 col-sm-12 col-xs-12 col-md-3">
                                        <MDBBtn size="md" onClick={this.SaveData} className="purchaseaddnew">
                                            <span className="Savedata">
                                                Save
                                            </span>
                                        </MDBBtn>
                                    </div>
                                    <div className="col-xl-2 col-xs-12 col-sm-12 col-lg-2 col-md-3" onClick={this.clearData}>
                                        <MDBBtn size="md" className="purchaseCancal">
                                            <span className="Savedata">
                                                Cancel
                                            </span>
                                        </MDBBtn>
                                    </div>
                                </div>
                                <div className="row">


                                    
                                   
                                   
                                   
                                </div>

                            </MDBCardBody>
                        </MDBCard>
                        <APILoader isOpen={this.state.isLoaderOpen} />
                        <APIResponse
                            isOpen={this.state.apiResponseModalOpen}
                            response={this.state.apiResponse}
                            msg={this.state.apiResponseMsg}
                            closeAPIResponse={this.closeAPIResponse}
                        />
                    </div>
                </div>
            </>
        );
    }
}
export default Addscrap;