import React, { Component } from "react";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import HttpAPI from "../common/api_calls";
import ApiUtils from "../common/api_utils";

import APIResponse from "../common/APIResponse";
import ConfirmMsg from "../common/ConfirmMsg";
import "./Styleing.css";
import {
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBInput,
  MDBBreadcrumb,
  MDBCard,
  MDBCardBody,
  MDBBreadcrumbItem,
  MDBIcon,
} from "mdbreact";
import CustomBreadCrumb from "../pages/sections/CustomBreadCrumb";

import Pagination from "../common/Pagination";

import APILoader from "../common/APILoader";
class CountryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryData: [],
      filterCountryData: [],
      countryColumns: [],
      countryName: "",
      updateRow: {},
      apiResponse: false,
      apiResponseModalOpen: false,
      apiResponseMsg: "",
      id: 0,
      errorMap: { countryName: { valid: true, error: "" } },
      confirmMsgOpen: false,
      confirmMsg: "",
      deleteRowId: 0,
      isLoaderOpen: false,
    };
    this.countryInput = React.createRef();
  }

  componentDidMount() {
    this.renderDataFromServer();
  }
  async renderDataFromServer() {
    // HttpAPI.getCountry().then(
    //     response => {
    //         var response = response.data;
    //         this.setState({ countryData: response.data,
    //             filterCountryData:response.data });
    //     }
    // );
    this.setState({
      isLoaderOpen: true,
    });
    let data = await HttpAPI.getCountry();
    let dataResponse = data.data;
    if (dataResponse.status === true) {
      this.setState({
        countryData: dataResponse.data,
        filterCountryData: dataResponse.data,
      });
    }
    this.setState({
      isLoaderOpen: false,
    });
  }
  closeAPIResponse = () => {
    this.setState({
      apiResponse: false,
      apiResponseMsg: "",
      apiResponseModalOpen: false,
    });
  };

  deleteRow = () => {
    this.setState({
      confirmMsgOpen: false,
      confirmMsg: "",
    });
    let id = this.state.deleteRowId;
    console.log("id print" + id);
    HttpAPI.deleteCountryById(id).then((response) => {
      var response = response.data;
      this.setState({
        deleteRowId: 0,
      });
      if (response.status === true) {
        this.setState({
          apiResponse: true,
          apiResponseMsg: "Country Delete Successfully",
          apiResponseModalOpen: true,
        });
        this.clearData();
        this.renderDataFromServer();
      } else {
        this.setState({
          apiResponse: false,
          apiResponseMsg: response.message,
          apiResponseModalOpen: true,
        });
      }
      window.setTimeout(() => {
        this.closeAPIResponse();
      }, 1000);
    });
  };

  cancelConfirm = () => {
    this.setState({
      deleteRowId: 0,
      confirmMsgOpen: false,
      confirmMsg: "",
    });
  };
  deleteRowById(id) {
    console.log(id);
    this.setState({
      deleteRowId: id,
      confirmMsgOpen: true,
      confirmMsg: "Are you sure want to delete?",
    });
  }
  getRowById(id) {
    HttpAPI.getCountryById(id).then((response) => {
      var response = response.data;
      if (response.status === true) {
        this.setState({
          countryName: response.data.countryName,
          updateRow: response.data,
          id: response.data.id,
        });

        this.setState({
          apiResponse: true,
          apiResponseMsg: response.message,
          apiResponseModalOpen: false,
        });
      } else {
        this.setState({
          apiResponse: false,
          apiResponseMsg: response.message,
          apiResponseModalOpen: true,
        });
      }
      window.setTimeout(() => {
        this.setState({
          apiResponse: false,
          apiResponseMsg: "",
          apiResponseModalOpen: false,
        });
      }, 1000);
    });
  }

  async createCountry() {
    this.setState({
      isLoaderOpen: true,
    });
    let data = {
      countryName: this.state.countryName,
    };
    let senddata = ApiUtils.appendCreateRequiredField(data);
    let countryData = await HttpAPI.createCountry(senddata);
    let countryResponse = countryData.data;
    if (countryResponse.status === true) {
      this.setState({
        isLoaderOpen: false,
        apiResponse: true,
        apiResponseMsg: "Country Insert Successfully",
        apiResponseModalOpen: true,
      });
      this.clearData();
      this.renderDataFromServer();
    } else {
      this.setState({
        isLoaderOpen: false,
        apiResponse: false,
        apiResponseMsg: countryResponse.message,
        apiResponseModalOpen: true,
      });
    }
    window.setTimeout(() => {
      this.setState({
        apiResponse: false,
        apiResponseMsg: "",
        apiResponseModalOpen: false,
      });
    }, 1000);
  }
  async UpdateCountry() {
    this.setState({
      isLoaderOpen: true,
    });
    let data = {
      id: this.state.updateRow.id,
      countryName: this.state.countryName,
      active: this.state.updateRow.active,
      createdAt: this.state.updateRow.createdAt,
      createdBy: this.state.updateRow.createdBy,
    };
    let senddata = ApiUtils.appendUpdateRequiredField(data);
    let updateData = await HttpAPI.UpdateCountry(senddata);
    let updateResponse = updateData.data;
    if (updateResponse.status === true) {
      this.setState({
        isLoaderOpen: false,
        apiResponse: true,
        apiResponseMsg: "Country Update Successfully",
        apiResponseModalOpen: true,
      });
      this.clearData();
      this.renderDataFromServer();
    } else {
      this.setState({
        isLoaderOpen: false,
        apiResponse: false,
        apiResponseMsg: updateResponse.message,
        apiResponseModalOpen: true,
      });
    }
    window.setTimeout(() => {
      this.setState({
        apiResponse: false,
        apiResponseMsg: "",
        apiResponseModalOpen: false,
      });
    }, 1000);
  }
  setInputVar = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  saveData = () => {
    if (this.validateData() === true) {
      if (this.state.id > 0) {
        this.UpdateCountry();
      } else {
        this.createCountry();
      }
    }
  };
  handleSearchChange = (event) => {
    this.globalSearch(event.target.value);
  };
  globalSearch = (searchInput) => {
    let filteredData = this.state.countryData.filter((value) => {
      return (
        value.countryName !== null &&
        value.countryName.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ filterCountryData: filteredData });
  };
  clearData = () => {
    this.setState({
      countryName: "",
      id: 0,
      updateRow: {},
      errorMap: { countryName: { valid: true, error: "" } },
    });
  };
  validateData() {
    let map = {
      countryName: { valid: true, error: "" },
    };
    let isValid = true;
    if (this.state.countryName === "") {
      //this.setState({ apiResponseMsg: 'Please fill Country Name' })
      map.countryName.valid = false;
      map.countryName.error = "Please fill Country Name";
      this.countryInput.current.focus();
      isValid = false;
    }
    this.setState({ errorMap: map });
    return isValid;
  }

  render() {
    const columns = [
      {
        id: "1",
        Header: () => <div className="bgcolor">Country Name</div>,
        accessor: "countryName",
        style: {
          height: 35,
          textAlign: "center",
        },
      },
      {
        id: "2",
        Header: () => <div className="bgcolor">Actions</div>,

        accessor: "id",

        sortable: false,
        style: {
          height: 35,
          textAlign: "center",
        },
        Cell: (props) => {
          return (
            <div>
              <div>
                <MDBIcon
                  className=" m-2"
                  icon="edit"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.getRowById(props.value)}
                />
                <MDBIcon
                  className=" m-2"
                  icon="trash"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.deleteRowById(props.value)}
                />
              </div>
            </div>
          );
        },
      },
    ];

    return (
      <div className="">
        <MDBRow className="justify-content-left ml-0">
          <MDBCol sm="9" className="d-flex flex-row-reverse">
            <CustomBreadCrumb items={["Master", "Country"]} linkTo={["", ""]} />
          </MDBCol>
        </MDBRow>

        <MDBRow className="justify-content-left ml-0">
          <MDBCol md="3" lg="3" xl="3"></MDBCol>

          <MDBCol lg="6">
            <MDBCard className="mb-5  ">
              <MDBCardBody className="align-items-center justify-content-between m-1">
                <div className="row justify-content-left ">
                  <div className="form-group col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12">
                    <label
                      for="Countryname"
                      style={{ "font-size": "0.7rem", color: "grey" }}
                    >
                      {" "}
                      <span>Country Name </span>
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      ref={this.countryInput}
                      name="countryName"
                      value={this.state.countryName}
                      onChange={this.setInputVar}
                      className="form-control form-text font-small m-0 p-0"
                      id="Countryname"
                    ></input>
                    <span className="error-message">
                      {this.state.errorMap.countryName.valid == true
                        ? ""
                        : this.state.errorMap.countryName.error}
                    </span>
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-8 col-xs-12 col-sm-12  countrybutton ">
                    <MDBBtn
                      onClick={this.saveData}
                      size="md"
                      className=" countrysavebutton"
                    >
                      <span className="Savedata">Add New</span>
                    </MDBBtn>
                    <MDBBtn
                      onClick={this.clearData}
                      size="md"
                      className="countrycancelbutton "
                    >
                      <span className="Savedata">Cancel</span>
                    </MDBBtn>
                  </div>
                </div>

                <div className="input-group md-form form-sm  form-2 pl-0 ">
                  <input
                    className="form-control my-0 py-0  lime-border"
                    type="text"
                    onChange={this.handleSearchChange}
                    placeholder="Search"
                    aria-label="Search"
                  ></input>
                  <div className="input-group-append">
                    <span
                      className="input-group-text gray lighten-2 "
                      id="basic-text1"
                    >
                      <i
                        className="fas fa-search text-grey"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </div>
                </div>

                <MDBRow className="justify-content-left districttable">
                  <MDBCol sm="12" className="mb-2">
                    <ReactTable
                      resizable={true}
                      minRows={1}
                      noDataText="NO DATA FOUND"
                      PaginationComponent={Pagination}
                      showPagination={true}
                      defaultPageSize={15}
                      style={{
                        fontSize: 14,
                        // This will force the table body to overflow and scroll, since there is not enough room
                      }}
                      data={this.state.filterCountryData}
                      // columns={columns}
                      columns={columns}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <APIResponse
            isOpen={this.state.apiResponseModalOpen}
            response={this.state.apiResponse}
            msg={this.state.apiResponseMsg}
            closeAPIResponse={this.closeAPIResponse}
          />
          <APILoader isOpen={this.state.isLoaderOpen} />
          <ConfirmMsg
            isOpen={this.state.confirmMsgOpen}
            msg={this.state.confirmMsg}
            yesMethod={this.deleteRow}
            noMethod={this.cancelConfirm}
          />
          <MDBCol lg="3"></MDBCol>
        </MDBRow>
      </div>
    );
  }
}
export default CountryPage;
