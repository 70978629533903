import React, { Component } from 'react';
import { MDBTabContent, MDBFormInline, MDBIcon, MDBContainer, MDBNavItem, MDBNavLink, MDBNav, MDBRow, MDBBtn, MDBInput, MDBBreadcrumb, MDBCard, MDBCardBody, MDBBreadcrumbItem, MDBCol, MDBCollapse, MDBTooltip } from 'mdbreact';
import Select from 'react-select';
import APIResponse from '../../common/APIResponse'
import HttpAPI from '../../common/api_calls';
import ApiUtils from '../../common/api_utils'
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import Pagination from "../../common/Pagination";
import PurchaseDetail from '../modal/PurchaseDetail'
import * as moment from 'moment';
import CustomBreadCrumb from '../sections/CustomBreadCrumb'
import APILoader from '../../common/APILoader'
moment.locale('en')
class InventoryMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyData: [],
            selectedcompany: '',
            companyOption: [{ id: 0, name: 'company', label: "", value: "" }],
            selectedmodel: '',
            modelOption: [{ id: 0, name: 'model', label: "", value: "" }],
            modelData: [],
            purchaseData: [],
            selectedPurchase: [],
            openModal: false,
            filteredData: [],
            filterPurchaseData: [],
            officeMasterData: [],
            officeAddressNameOption: [],
            officeAddressTypeOption: [],
            selectedofficeaddressType: '',
            selectedofficeaddressName: '',
            officeaddressType: '',
            officeaddressName: '',
            officeaddressNameId: 0,
            inlineAddressType: '',
            inlineAddressName: '',
            vendorNameOption: [{ id: 0, name: 'vendor', label: "", value: "" }],
            selectedvendor: '',
            vendorName: '',
            vendorId: 0,
            company: '',
            model: '',
            companyName: '',
            modelName: '',
            companyId: 0,
            modelId: 0,
            editId: 0,
            editRecord: {},
            editPurchaseType: '',
            isLoaderOpen: false,
            vendorData: [],
        }
        this.addressTypeInput = React.createRef();
        this.addressNameInput = React.createRef();
    }

    changeVendor = (e) => {
        this.setState({
            selectedvendor: e,
            vendorId: e.id,
            vendorName: e.value
        })
    }
    async renderDataFromServer() {
        this.setState({isLoaderOpen:true})
        HttpAPI.getCompany().then(
            response => {
                var response = response.data;
                this.setState({
                    companyData: response.data
                })
                var arr = []
                arr.push({ id: 0, name: 'company', label: '', value: '' })
                response.data.map((item, key) => {
                    arr.push({ id: item.id, name: 'company', label: item.description, value: item.description })
                })
                this.setState({ companyOption: arr })
            }
        );
        HttpAPI.getModel().then(
            response => {
                var response = response.data;
                this.setState({
                    modelData: response.data
                })

            }
        );

        HttpAPI.getAddressType().then(response => {
            var response = response.data;

            let uniqAddressType = []
            let arr = []
            response.data.map(row => {
                if (uniqAddressType.indexOf(row.addressType) === -1) {
                    uniqAddressType.push(row.addressType)
                    arr.push({ name: 'officeAddressType', label: row.addressType, value: row.addressType })
                }
            })
            this.setState({
                officeMasterData: response.data,
                officeAddressTypeOption: arr
            })
        })
        HttpAPI.getContact().then(response => {
            var response = response.data;
            if (response.status === true) {
                let arr = []
                response.data.map(item => {
                    arr.push({
                        id: item.id, name: 'vendor',
                        label: item.contactType + '-' + item.firstName + ' ' + item.lastName,
                        value: item.contactType + '-' + item.firstName + ' ' + item.lastName,
                    })
                })
                this.setState({
                    vendorNameOption: arr,
                    vendorData: response.data
                })

            }
        })
        let stockData = await HttpAPI.getStock()
        // HttpAPI.getStock().then(
        //     response => {
        //         var response = response.data;
        //         this.setState({
        //             purchaseData: response.data,
        //             filterPurchaseData: response.data
        //         });
        //     }
        // );
        var stockResponse = stockData.data
        if (stockResponse.status === true) {
            this.setState({
                purchaseData: stockResponse.data,
                filterPurchaseData: stockResponse.data
            });
        }
        this.setState({isLoaderOpen:false})
    }
    componentDidMount() {
        this.renderDataFromServer();
    }
    changeSelectVar = (e) => {

        this.setState({
            [e.name + 'Id']: Number(e.id),
            [e.name + 'Name']: e.value,
            ['selected' + e.name]: e
        });
    }
    changeCompanyVar = (e) => {
        var filterId = Number(e.id);
        const data = this.state.modelData.filter(row => row.companyId === filterId);

        // this.setState({
        //     filterModel: data
        // });
        var arr = []
        arr.push({ id: 0, name: 'model', label: '', value: '' })
        data.map(item => {
            arr.push({ id: item.id, name: 'model', label: item.description, value: item.description })
        })

        this.setState({
            selectedmodel: '',
            modelId: 0,
            modelName: '',
            modelOption: arr,

        })
        this.changeSelectVar(e);
    }
    changeSelectVarData = (e) => {
        this.setState({
            editPurchaseType: e.target.value,
        })
    }
    changeAddressType = (e) => {
        this.setState({
            inlineAddressType: e.target.value,
        })
    }
    changeAddressName = (e) => {
        this.setState({
            inlineAddressName: e.target.value,
        })
    }
    ViewPuchaseDetail(id) {
        HttpAPI.getPurchaseById(id).then(
            response => {
                var response = response.data;

                this.setState({ selectedPurchase: response.data })
                this.setState({ openModal: true })
            }
        );
    }
    soldOut(id) {
        window.localStorage.setItem('editsellId', 0)
        window.localStorage.setItem('inventorySell', id)
        window.location.href = '/addSell'
    }
    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
    }
    handleSearchChange = event => {
        this.globalSearch(event.target.value);
    };
    globalSearch = (searchInput) => {
        let filteredData = this.state.purchaseData.filter(value => {
            return (
                (value.purchaseNumber !== null && value.purchaseNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.bikeNumber !== null && value.bikeNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.companyMST.description !== null && value.companyMST.description.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.modelMST.description !== null && value.modelMST.description.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.purchaseAmount !== null && value.purchaseAmount.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.purchaseDate !== null && moment(value.purchaseDate).format('DD/MM/YYYY').toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.contactMST.firstName !== null && value.contactMST.firstName.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.contactMST.mobileNumber !== null && value.contactMST.mobileNumber.toLowerCase().includes(searchInput.toLowerCase()))

            );
        });
        this.setState({ filterPurchaseData: filteredData })
    }
    async filterClick(){
        this.setState({
            isLoaderOpen:true
        })
        let criteria = ''
        if (this.state.companyId > 0) {
            criteria = criteria + 'company_id=' + this.state.companyId
        }

        if (this.state.modelId > 0) {
            criteria = criteria + '&model_id=' + this.state.modelId
        }
        if (this.state.officeaddressType !== '') {
            criteria = criteria + '&addressType=' + this.state.officeaddressType
        }
        if (this.state.officeaddressName !== '') {
            criteria = criteria + '&addressName=' + this.state.officeaddressName
        }
        if (this.state.vendorId > 0) {
            criteria = criteria + '&customer_id=' + this.state.vendorId
        }
        if (criteria !== '') {
            let filterData=await HttpAPI.getStockFilter(criteria)
            let filterResponse=filterData.data
            if(filterResponse.status===true){
                this.setState({ purchaseData: filterResponse.data, filterPurchaseData: filterResponse.data })
            }else{  
                this.setState({ purchaseData: [], filterPurchaseData: [] })
            }
           
        }
        this.setState({
            isLoaderOpen:false
        })
    }
    searchClick = () => {
        this.filterClick()
    }
    clearSearch = () => {
        this.setState({
            company: '',
            model: '',
            companyName: '',
            modelName: '',
            companyId: 0,
            modelId: 0,
            selectedcompany: '',
            selectedmodel: '',
            selectedofficeAddressType: '',
            selectedofficeAddressName: '',
            inlineAddressType: '',
            inlineAddressName: '',
            selectedvendor: '',
            vendorId: 0,
            vendorName: ''
        })
        this.renderDataFromServer();
    }
    validateData = () => {

        if (this.state.inlineAddressType === '' && this.state.inlineAddressName === '') {
            return true
        } else if (this.state.inlineAddressType !== '' && this.state.inlineAddressName !== '') {
            return true
        } else {
            if (this.state.inlineAddressType === '') {
                this.addressTypeInput.current.focus()
            } else if (this.state.inlineAddressName === '') {
                this.addressNameInput.current.focus()
            }
            return false;
        }
    }
    saveEntry = () => {
        if (this.validateData() === true) {

            this.setState({ isLoaderOpen: true })
            this.state.editRecord.purchaseFor = this.state.editPurchaseType
            if (this.state.inlineAddressType === '' && this.state.inlineAddressName === '') {
                this.state.editRecord.addressTypeId = 0
            } else {
                let officeMasterRecord = this.state.officeMasterData.filter(row =>
                    row.addressType === this.state.inlineAddressType &&
                    row.addressName === this.state.inlineAddressName
                )[0]
                this.state.editRecord.addressTypeId = officeMasterRecord.id
            }
            this.state.editRecord.updatedAt = ApiUtils.getCurrentTimeStamp()
            this.state.editRecord.updatedBy = ApiUtils.getLoggedInUser()
            HttpAPI.updatePurchase(this.state.editRecord).then(response => {
                var response = response.data
                if (response.status === true) {
                    let dataIndex = this.state.purchaseData.findIndex(row => row.id === this.state.editRecord.id)
                    this.state.purchaseData[dataIndex].purchaseFor = this.state.editPurchaseType
                    let index = this.state.filterPurchaseData.findIndex(row => row.id === this.state.editRecord.id)
                    this.state.filterPurchaseData[index].purchaseFor = this.state.editPurchaseType
                    if (this.state.inlineAddressType === '' && this.state.inlineAddressName === '') { }
                    else {
                        let officeMasterRecord = this.state.officeMasterData.filter(row =>
                            row.addressType === this.state.inlineAddressType &&
                            row.addressName === this.state.inlineAddressName
                        )[0]
                        this.state.purchaseData[dataIndex].addressTypeId = officeMasterRecord.id
                        this.state.filterPurchaseData[index].addressTypeId = officeMasterRecord.id

                        this.state.purchaseData[dataIndex].addressTypeMST = officeMasterRecord
                        this.state.filterPurchaseData[index].addressTypeMST = officeMasterRecord
                    }


                    this.setState({
                        editId: 0,
                        editPurchaseType: '',
                        editRecord: {},
                        inlineAddressName: '',
                        inlineAddressType: '',
                        isLoaderOpen: false
                    })
                } else {
                    this.setState({
                        editId: 0,
                        editPurchaseType: '',
                        editRecord: {},
                        inlineAddressName: '',
                        inlineAddressType: '',
                        isLoaderOpen: false
                    })
                }
            });


        }
    }
    changeOfficeAddressType = (e) => {
        let addressName = this.state.officeMasterData.filter(row => row.addressType === e.value)
        let arr = []
        addressName.map(row => {
            arr.push({ id: row.id, name: 'officeAddressName', label: row.addressName, value: row.addressName })
        })

        this.setState({
            selectedofficeAddressType: e,
            officeAddressNameOption: arr,
            officeaddressType: e.value,

            selectedofficeAddressName: '',
            officeaddressName: '',
            officeaddressNameId: 0

        })
    }
    changeOfficeAddressName = (e) => {
        this.setState({
            selectedofficeAddressName: e,
            officeaddressName: e.value,
            officeaddressNameId: e.id
        })
    }
    cancelEntry = () => {
        this.setState({
            editId: 0,
            editPurchaseType: '',
            isLoaderOpen: false,
            inlineAddressName: '',
            inlineAddressType: '',
        })
    }
    editEntry = row => (e) => {

        if (row.addressTypeMST !== null) {
            this.setState({
                inlineAddressName: row.addressTypeMST.addressName,
                inlineAddressType: row.addressTypeMST.addressType
            })
        } else {
            this.setState({
                inlineAddressName: '',
                inlineAddressType: ''
            })
        }
        this.setState({
            editId: row.id,
            editPurchaseType: row.purchaseFor,
            editRecord: row
        })


    }
    // getActionCell = (props) => {

    //     if (props.original.id !== this.state.editId) {
    //         return (<div >
    //             <select data-param={props.original} name="purchaseFor" value={props.value} onChange={()=>this.changeSelectVarData(props)} id={props.original.id} class="browser-default md-select form-control form-control-sm" >
    //                 <option value="Retail"  >Retail</option>
    //                 <option value="Scrap" >Scrap</option>
    //             </select>
    //         </div>)
    //     } else {
    //         return (<div>
    //             <MDBBtn size='sm' onClick={this.saveEntry}>Save</MDBBtn>
    //             <MDBBtn size='sm'>Cancel</MDBBtn>
    //         </div>)
    //     }
    // }
    closePurchaseDetail = () => {
        this.setState({ selectedPurchase: {} })
        this.setState({ openModal: false })
    }
    render() {
        const columns = [
            {
                id: "1",
                Header: () => (
                    <div className="bgcolor">
                        Sr No.
                    </div>
                ),
                accessor: 'id',
                width: 100,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Footer: row => {
                    let arrSum = row.data.
                        map(maprow => maprow._original);
                    const sum = arrSum.length
                    return <div><span style={{ 'font-weight': 'bold' }} title={sum}>{sum}</span> </div>;
                },
                getFooterProps: () => {
                    return {
                        style: {
                            background: "#bdbdbd"
                        }
                    }
                }
            },
            {
                id: "2",

                Header: () => (
                    <div className="bgcolor">
                        <div>Purchase Date</div>

                    </div>
                ),
                accessor: d => moment(d.purchaseDate).format('DD/MMM/YYYY'),
                width: 120,
                style: {
                    height: 35,
                    textAlign: 'center',
                }
            },
            {
                id: "3",

                Header: () => (
                    <div className="bgcolor">
                        <div>Purchase No</div>

                    </div>
                ),
                accessor: "purchaseNumber",
                width: 100,
                style: {
                    height: 35,
                    textAlign: 'center',
                }
            },
            {
                id: "4",

                Header: () => (
                    <div className="bgcolor">
                        <div>Vehicle Number</div>

                    </div>
                ),
                accessor: "bikeNumber",
                width: 120,
                style: {
                    height: 35,
                    textAlign: 'center',
                }
            },

            {
                id: "5",

                Header: () => (
                    <div className="bgcolor">
                        <div>Company</div>

                    </div>
                ),
                accessor: "companyMST.description",
                width: 80,
                style: {
                    height: 35,
                    textAlign: 'center',
                }
            },
            {
                id: "6",

                Header: () => (
                    <div className="bgcolor">
                        <div>Model</div>

                    </div>
                ),
                accessor: "modelMST.description",
                width: 80,
                style: {
                    height: 35,
                    textAlign: 'center',
                }
            },
            {
                id: "7",

                Header: () => (
                    <div className="bgcolor">
                        <div>Purchase Amount</div>

                    </div>
                ),
                accessor: "purchaseAmount",
                width: 130,
                style: {
                    height: 35,
                    textAlign: 'center',
                }
            },

            {
                id: "8",

                Header: () => (
                    <div className="bgcolor">
                        <div>Vendor Name</div>

                    </div>
                ),
                accessor: "contactMST.firstName",
                width: 110,
                style: {
                    height: 35,
                    textAlign: 'center',
                }
            },
            {
                id: "9",

                Header: () => (
                    <div className="bgcolor">
                        <div>Vendor Number</div>

                    </div>
                ),
                accessor: "contactMST.mobileNumber",
                width: 120,
                style: {
                    height: 35,
                    textAlign: 'center',
                }
            },
            {
                id: "10",

                Header: () => (
                    <div className="bgcolor">
                        <div>Purchase For</div>

                    </div>
                ),
                accessor: "purchaseFor",
                width: 120,
                style: {
                    height: 40,
                    textAlign: 'center',
                },
                Cell: props => {
                    if (Number(props.original.id) === Number(this.state.editId)) {
                        return (<div >
                            <select name="purchaseFor" value={this.state.editPurchaseType} onChange={this.changeSelectVarData} id={props.original.id} class="browser-default md-select form-control form-control-sm" >
                                <option value="Retail"  >Retail</option>
                                <option value="Scrap" >Scrap</option>
                                <option value="Exchange" >Exchange</option>
                            </select>
                        </div>)
                    } else {
                        return (<div>
                            <select name="purchaseFor" disabled={true} value={props.value} onChange={this.changeSelectVarData} id={props.original.id} class="browser-default md-select form-control form-control-sm" >
                                <option value="Retail"  >Retail</option>
                                <option value="Scrap" >Scrap</option>
                                <option value="Exchange" >Exchange</option>
                            </select>
                        </div>)
                    }
                }
            },
            {
                id: "11",

                Header: () => (
                    <div className="bgcolor">
                        <div>Office Address Type</div>

                    </div>
                ),
                accessor: d => d.addressTypeMST == null ? '' : d.addressTypeMST.addressType,
                width: 150,
                style: {
                    height: 40,
                    textAlign: 'center',
                },
                Cell: props => {
                    if (Number(props.original.id) === Number(this.state.editId)) {
                        return (<div >
                            <select name="addType" ref={this.addressTypeInput} value={this.state.inlineAddressType} onChange={this.changeAddressType} id={props.original.id} class="browser-default md-select form-control form-control-sm" >
                                <option value=''  >{''}</option>
                                {this.state.officeAddressTypeOption.map(row => {
                                    return (<option value={row.value}  >{row.value}</option>)
                                })}

                            </select>

                        </div>)
                    } else {
                        return (<div >
                            <select name="addType" disabled={true} value={props.original.addressTypeMST === null ? '' : props.original.addressTypeMST.addressType} onChange={this.changeAddressType} id={props.original.id} class="browser-default md-select form-control form-control-sm" >
                                <option value={props.original.addressTypeMST === null ? '' : props.original.addressTypeMST.addressType}  >{props.original.addressTypeMST === null ? '' : props.original.addressTypeMST.addressType}</option>
                            </select>
                        </div>)
                    }
                }
            },
            {
                id: "12",

                Header: () => (
                    <div className="bgcolor">
                        <div>Office Address Name</div>
                    </div>
                ),
                //accessor: d=>d.addressTypeMST==null?'':d.addressTypeMST.addressName,
                width: 150,
                style: {
                    height: 40,
                    textAlign: 'center',
                },
                Cell: props => {

                    if (Number(props.original.id) === Number(this.state.editId)) {
                        return (<div >
                            <select name="addName" ref={this.addressNameInput} value={this.state.inlineAddressName} onChange={this.changeAddressName} id={props.original.id} class="browser-default md-select form-control form-control-sm" >
                                <option value=''  >{''}</option>
                                {this.state.officeMasterData.filter(row =>
                                    this.state.inlineAddressType === row.addressType
                                ).map(row => {
                                    return (<option value={row.addressName}  >{row.addressName}</option>)
                                })}

                            </select>

                        </div>)
                    } else {

                        return (<div >
                            <select name="addName" disabled={true} value={props.original.addressTypeMST === null ? '' : props.original.addressTypeMST.addressName} onChange={this.changeAddressName} id={props.original.id} class="browser-default md-select form-control form-control-sm" >
                                <option value={props.original.addressTypeMST === null ? '' : props.original.addressTypeMST.addressName}  >{props.original.addressTypeMST === null ? '' : props.original.addressTypeMST.addressName}</option>
                            </select>

                        </div>)
                    }
                }
            },
            {
                id: "13",

                Header: () => (
                    <div className="bgcolor">
                        <div>RC Received</div>

                    </div>
                ),
                accessor: d => d.rcBookReceived === true ? 'Yes' : 'No',
                width: 100,
                style: {
                    height: 35,
                    textAlign: 'center',
                }
            },
            {
                id: "14",

                Header: () => (
                    <div className="bgcolor">
                        <div>View Details</div>

                    </div>
                ),
                accessor: 'id',
                width: 100,
                style: {
                    height: 35,
                    textAlign: 'center',
                },
                Cell: props => {

                    return (
                        <div>
                            <div>

                            </div>
                            <div>
                                <MDBIcon className=" m-2" icon='eye' style={{ cursor: 'pointer' }} onClick={() => this.ViewPuchaseDetail(props.value)} />

                            </div>
                        </div>
                    );
                },

            }, {
                id: "15",

                Header: () => (
                    <div className="bgcolor">
                        <div>Sell</div>

                    </div>
                ),
                accessor: 'id',
                width: 100,
                style: {
                    height: 35,
                    textAlign: 'center',
                },
                Cell: props => {

                    return (
                        <div>
                            <div>

                            </div>
                            <div>

                                <MDBBtn className='text-left' style={{ 'padding': '4px' }} onClick={() => this.soldOut(props.value)} size='sm'>Sell it</MDBBtn>
                            </div>
                        </div>
                    );
                },

            },
            {
                id: "16",
                Header: () => (
                    <div className="bgcolor">
                        Action
                    </div>
                ),
                width: 130,
                filterable: false,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Cell: props => {
                    if (Number(props.original.id) === Number(this.state.editId)) {
                        return (<div>
                            <MDBBtn className='text-left' style={{ 'padding': '4px' }} onClick={this.saveEntry} size='sm'>Save</MDBBtn>
                            <MDBBtn className='text-left' style={{ 'padding': '4px' }} size='sm' onClick={this.cancelEntry}>Cancel</MDBBtn>
                        </div>)
                    } else {
                        return (<MDBBtn className='text-left' style={{ 'padding': '4px' }} onClick={this.editEntry(props.original)} size='sm'>Edit</MDBBtn>)

                    }
                }
            }

        ];
        return (
            <React.Fragment>

                <MDBRow className="justify-content-left ml-0">


                    <div className="col-xl-12 d-flex flex-row-reverse">
                        <CustomBreadCrumb items={['Stock', 'Inventory']} linkTo={['', '']} />
                    </div>
                </MDBRow>
                <div className="col-sm-12">
                    <MDBCard className="">
                        <MDBCardBody>
                            <div className="row justify-content-left ml-0">
                                <div className="form-group col-xl-5 col-lg-5 col-md-5">
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6">
                                    <MDBBtn className="Filterbutton" size="sm" onClick={this.toggleCollapse('filterdata')}>
                                        <span className="Savedata">
                                            Add Filter
                                            </span>
                                    </MDBBtn>
                                </div>
                            </div>
                            <div>
                                <MDBCollapse id='filterdata' isOpen={this.state.collapseID}>
                                    <div className="row justify-content-left ml-0">
                                        <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                            <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Company</label>
                                            <Select name='company' options={this.state.companyOption} value={this.state.selectedcompany} onChange={this.changeCompanyVar} id="select" class="input-field browser-default md-select form-control form-control-sm">
                                            </Select>
                                        </div>
                                        <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-xs-12 ">
                                            <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Model</label>
                                            <Select name="model" options={this.state.modelOption} value={this.state.selectedmodel} onChange={this.changeSelectVar} id="model" class="browser-default md-select form-control form-control-sm">
                                            </Select>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="AddressTypeId" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  > <span>Office Address Type</span><span style={{ color: 'red' }}> *</span></label>
                                            <Select name="officeAddressType" ref={this.officeAddressTypeInput} value={this.state.selectedofficeAddressType} options={this.state.officeAddressTypeOption} onChange={this.changeOfficeAddressType} id="AddressTypeId" class="browser-default md-select form-control form-control-sm" >
                                            </Select>

                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="AddressNameId" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  > <span>Office Address Name</span><span style={{ color: 'red' }}> *</span></label>
                                            <Select name="officeAddressName" ref={this.officeAddressNameInput} value={this.state.selectedofficeAddressName} options={this.state.officeAddressNameOption} onChange={this.changeOfficeAddressName} id="AddressNameId" class="browser-default md-select form-control form-control-sm" >
                                            </Select>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="vendorNameId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Vendor Name</label>
                                            <Select name="vendor" options={this.state.vendorNameOption} value={this.state.selectedvendor} onChange={this.changeVendor} id="vendorNameId" class="input-field browser-default md-select form-control form-control-sm" >
                                            </Select>
                                        </div>
                                        <div className="col-xl-4 col-lg-8 col-md-8 col-sm-12 col-xs-12 inventerydata">
                                            <MDBBtn className="  purchaseinvantorysave  btn-md " size='sm' onClick={this.searchClick}><i className="fas fa-filter"></i><span className="Savedata pl-1 ">Apply filter</span></MDBBtn>
                                            <MDBBtn className=" purchaseinventoryCancal  " size='sm' onClick={this.clearSearch}><i className="fas fa-undo-alt"></i><span className="Savedata pl-1">  Clear Filter</span></MDBBtn>
                                        </div>
                                    </div>
                                </MDBCollapse>
                                <div className="row">
                                    <div className="input-group md-form form-sm form-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                        <input className="form-control my-0 py-0  lime-border" type="text" onChange={this.handleSearchChange} placeholder="Search" aria-label="Search"></input>
                                        <div className="input-group-append">
                                            <span className="input-group-text gray lighten-2" id="basic-text1"><i className="fas fa-search text-grey"
                                                aria-hidden="true"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="row mt-3">
                                <div className="col-sm-12">
                                    <ReactTable
                                        resizable={false}
                                        minRows={1}
                                        noDataText="NO DATA FOUND"
                                        showPagination={true}
                                        PaginationComponent={Pagination}
                                        defaultPageSize={15}

                                        style={{
                                            fontSize: 14
                                            // This will force the table body to overflow and scroll, since there is not enough room
                                        }}

                                        columns={columns}
                                        data={this.state.filterPurchaseData}

                                    />
                                </div>
                            </div>
                            <APILoader isOpen={this.state.isLoaderOpen} />
                            <PurchaseDetail isOpen={this.state.openModal} purchaseRecord={this.state.selectedPurchase} closePurchaseDetail={this.closePurchaseDetail} />
                        </MDBCardBody>
                    </MDBCard>
                </div>


            </React.Fragment>
        );
    }

}
export default InventoryMaster;