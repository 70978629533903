import React, { Component } from "react";
import { MDBRow, MDBFormInline, MDBIcon, MDBInput, MDBCard, MDBTabContent, MDBTabPane, MDBContainer, MDBNavItem, MDBNavLink, MDBNav, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBCardBody, MDBBreadcrumbItem, MDBCol, MDBCollapse } from 'mdbreact';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import CustomBreadCrumb from '../pages/sections/CustomBreadCrumb';
import Select from 'react-select';
import DateRangePickerModal from '../common/DateRangePickerModal'
import * as moment from 'moment';
import HttpAPI from '../common/api_calls';
import ApiUtils from '../common/api_utils';
import APIResponse from '../common/APIResponse'
import Pagination from "../common/Pagination";
import { ExportToCsv } from 'export-to-csv';
import APILoader from '../common/APILoader'
moment.locale('en')
const columns = [
    {
        id: "1",
        Header: () => (
            <div className="bgcolor">
                Date
            </div>
        ),
        headerLabel: 'Date',
        accessor: d => moment(d.createdAt).format('DD/MMM/YYYY'),
        width: 120,
        style: {
            height: 35,
            textAlign: 'Center',

        },
    },
    {
        id: "2",
        Header: () => (
            <div className="bgcolor">
                Transaction Type
            </div>
        ),
        headerLabel: 'Transaction Type',
        accessor: 'transactionType',
        width: 130,
        style: {
            height: 35,
            textAlign: 'Center',

        },
    },
    {
        id: "3",
        Header: () => (
            <div className="bgcolor">
                Item Name
            </div>
        ),
        headerLabel: 'Item Name',
        accessor: 'itemName',
        width: 130,
        style: {
            height: 35,
            textAlign: 'Center',

        },
    },
    {
        id: "4",
        Header: () => (
            <div className="bgcolor">
                Company
            </div>
        ),
        headerLabel: 'Company',
        accessor: 'company',
        width: 100,
        style: {
            height: 35,
            textAlign: 'Center',

        },
    },
    {
        id: "5",
        Header: () => (
            <div className="bgcolor">
                Model
            </div>
        ),
        headerLabel: 'Model',
        accessor: 'model',
        width: 100,
        style: {
            height: 35,
            textAlign: 'Center',

        },
    },
    {
        id: "6",
        Header: () => (
            <div className="bgcolor">
                Customer Name
            </div>
        ),
        headerLabel: 'Customer Name',
        accessor: 'customerName',
        width: 150,
        style: {
            height: 35,
            textAlign: 'Center',

        },
    },
    {
        id: "7",
        Header: () => (
            <div className="bgcolor">
                Customer Mob
            </div>
        ),
        headerLabel: 'Customer Mob',
        accessor: 'mobileNumber',
        width: 150,
        style: {
            height: 35,
            textAlign: 'Center',

        },
    },
    {
        id: "8",
        Header: () => (
            <div className="bgcolor">
                Purchase Amount
            </div>
        ),
        headerLabel: 'Purchase Amount',
        accessor: d => d.transactionType === 'Purchase' ? d.paidAmount : '',
       
        width: 130,
        style: {
            height: 35,
            textAlign: 'Center',

        },
        Footer: row => {
            let arrSum = row.data.
                map(maprow => maprow._original);
            const sum = arrSum
                .map(d => d.transactionType === 'Purchase' ? d.paidAmount : 0)
                .reduce((a, b) => a + b, 0);
            return <div><span style={{ 'font-weight': 'bold' }} title={ApiUtils.getNumericFormat(sum)}>{ApiUtils.getNumericFormat(sum)}</span> </div>;
        },
        getFooterProps: () => {
            return {
                style: {
                    background: "#bdbdbd"
                }
            }
        }

    },

    {
        id: "9",
        Header: () => (
            <div className="bgcolor">
                Sales Amount
            </div>
        ),
        headerLabel: 'Sales Amount',
        accessor: d => d.transactionType === 'Sales' ? d.paidAmount : '',
        width: 130,
        style: {
            height: 35,
            textAlign: 'Center',

        },
        Footer: row => {
            let arrSum = row.data.
                map(maprow => maprow._original);
            const sum = arrSum
                .map(d => d.transactionType === 'Sales' ? d.paidAmount : 0)
                .reduce((a, b) => a + b, 0);
            return <div><span style={{ 'font-weight': 'bold' }} title={ApiUtils.getNumericFormat(sum)}>{ApiUtils.getNumericFormat(sum)}</span> </div>;
        },
        getFooterProps: () => {
            return {
                style: {
                    background: "#bdbdbd"
                }
            }
        }
    }

];

class Scrappage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateLabel: '',
            isOpenDateRange: false,
            dataToDownload: [],
            startDate: null,
            endDate: null,
            Scrapdata: [],
            transactiontype: '',
            selectedtransactiontype: '',
            purchasesalesoption: [{ name: 'transactiontype', label: '', value: '' }, { name: 'transactiontype', label: 'Purchase', value: 'Purchase' }, { name: 'transactiontype', label: 'Sales', value: 'Sales' }, { name: 'transactiontype', label: 'Both', value: 'Both' }],
            filterScrapData: [],
            errorMap: { 'transactiontype': { 'valid': true, 'error': '' }, },
            isLoaderOpen:false

        }
    }
    onToggleDateControl = () => {
        this.setState({ isOpenDateRange: true })
    }
    hangleDateRange = (selectedStartDate, selectedEndDate) => {
        if (selectedStartDate != null && selectedStartDate != null) {
            let dt=new Date(selectedStartDate)
            let dt2=new Date(selectedEndDate)
            this.setState({
                startDate: dt.setHours(0,0,0,0),
                endDate: dt2.setHours(23,59,59,0),
                isOpenDateRange: false,
                dateLabel: moment(selectedStartDate).format('DD/MM/YYYY').toString() + '-' + moment(selectedEndDate).format('DD/MM/YYYY').toString()
            })
        } else {
            this.setState({
                startDate: null,
                endDate: null,
                isOpenDateRange: false,
                dateLabel: ''
            })
        }

    }

    setSelectInputVar = (e) => {

        this.setState({
            [e.name]: e.value,
            ['selected' + e.name]: e
        });
    }
    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
    }
    openAddScrap = () => {
        window.location.href = '/addscrapform'
    }
    componentDidMount() {
        this.renderDataFromServer();


    }
    async renderDataFromServer() {
        this.setState({
            isLoaderOpen:true
        })
        // HttpAPI.getScrap().then(
        //     response => {
        //         var response = response.data;
        //         this.setState({
        //             Scrapdata: response.data,
        //             filterScrapData: response.data
        //         });
        //     }
        // );
        let data=await HttpAPI.getScrap()
        let dataResponse=data.data
        if(dataResponse.status===true){
            this.setState({
                Scrapdata: dataResponse.data,
                filterScrapData: dataResponse.data
            });
        }
        this.setState({
            isLoaderOpen:false
        })
    }
    closeAPIResponse = () => {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }
    handleSearchChange = event => {
        this.globalSearch(event.target.value);
    };
    globalSearch = (searchInput) => {
        let filteredData = this.state.Scrapdata.filter(value => {
            return (
                (value.createdAt !== null && moment(value.createdAt).format('DD/MMM/YYYY').toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.transactionType !== null && value.transactionType.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.itemName !== null && value.itemName.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.company !== null && value.company.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.model !== null && value.model.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.customerName !== null && value.customerName.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.mobileNumber !== null && value.mobileNumber.toLowerCase().includes(searchInput.toLowerCase())) ||

                (value.paidAmount !== null && value.paidAmount.toString().includes(searchInput))
            );
        });

        this.setState({ filterScrapData: filteredData });
    }
    download = (event) => {
        const currentRecords = this.reactTable.getResolvedState().sortedData;
        var data_to_download = []
        for (var index = 0; index < currentRecords.length; index++) {
            let record_to_download = {}
            for (var colIndex = 0; colIndex < columns.length; colIndex++) {
                record_to_download[columns[colIndex].headerLabel] = currentRecords[index][columns[colIndex].id]
            }
            data_to_download.push(record_to_download)
        }
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data_to_download);
    }
    async filterClick(){
        this.setState({
            isLoaderOpen:true
        })
        let criteria = ''

        if (this.state.transactiontype !== '') {
            criteria = criteria + 'type=' + this.state.transactiontype.toLowerCase()
        }

        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        if (this.state.startDate !== null) {
            criteria = criteria + "&from=" + (new Date(this.state.startDate )).toISOString()
        }
        if (this.state.endDate !== null) {
            criteria = criteria + "&to=" + (new Date(this.state.endDate)).toISOString()
        }

        if (criteria !== '') {
            let filterData=await HttpAPI.getscrapFilter(criteria)
            let filterResponse=filterData.data
            if(filterResponse.status===true){
                this.setState({ Scrapdata: filterResponse.data, 
                    filterScrapData: filterResponse.data })
            }else{
                this.setState({ Scrapdata: [], 
                    filterScrapData: [] })
            }
           
        }
        this.setState({
            isLoaderOpen:false
        })
    }
    searchClick = () => {
        if (this.validateData() === true) {
            this.filterClick()
        }

    }
    validateData() {
        let map = {
            'transactiontype': { 'valid': true, 'error': '' },
        }
        let isValid = true;
        if (this.state.transactiontype === '') {
            //this.setState({ apiResponseMsg: 'Please fill Country Name' })
            map.transactiontype.valid = false;
            map.transactiontype.error = 'Please fill Scrap Sales '
            // this.transactiontypeInput.current.focus();
            isValid = false
        }
        this.setState({ errorMap: map })
        return isValid;
    }

    clearSearch = () => {

        this.setState({
            transactiontype: '',
            selectedtransactiontype: '',
            startDate: null,
            endDate: null,
            dateLabel: '',
        })
        this.renderDataFromServer();
    }


    render() {


        return (
            <React.Fragment>
                <div className="row justify-content-left ml-0">
                    <div className="col-sm-12 d-flex flex-row-reverse">
                        <CustomBreadCrumb items={['General', 'Scrap']} linkTo={['', '']} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <MDBCard className="mb-6 mr-0  ">
                            <MDBCardBody className="align-items-center justify-content-between mr-0">
                                <div className="row justify-content-left">
                                    <div className="col-xl-5 col-md-5">

                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <MDBBtn className="Filterbutton" size="sm" onClick={this.toggleCollapse('filterdata')}>
                                            <span className="Savedata">
                                                Add Filter
                                            </span>
                                        </MDBBtn>

                                    </div>
                                </div>
                                <MDBCollapse id='filterdata' isOpen={this.state.collapseID}>
                                    <div className="row m-0 p-0">

                                        <div className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-xs-12  ">
                                            <label for="Scrap" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Scrap Sales</label>
                                            <Select name="transactiontype" options={this.state.purchasesalesoption} value={this.state.selectedtransactiontype} onChange={this.setSelectInputVar} id="purchase" class="input-field browser-default md-select form-control form-control-sm" >
                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.transactiontype.valid == true ? '' : this.state.errorMap.transactiontype.error}</span>
                                        </div>
                                        <div className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-xs-12 form-group ">
                                            <label for="purchaseDate" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Date Range</label>
                                            <input
                                                value={this.state.dateLabel} classname="" onClick={this.onToggleDateControl} className="daterange form-control h-50" />

                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12 ">

                                            <MDBBtn className="scrapbuttondata   btn-md " size='sm' onClick={this.searchClick}><i className="fas fa-filter"></i><span className="Savedata pl-1 ">Apply filter</span></MDBBtn>
                                            <MDBBtn className=" scrapbuttondata  " size='sm' onClick={this.clearSearch}><i className="fas fa-undo-alt"></i><span className="Savedata pl-1">  Clear Filter</span></MDBBtn>
                                        </div>

                                    </div>
                                </MDBCollapse>

                                <div className="row justify-content-left">
                                    <div className="input-group md-form form-sm form-2 col-xl-8 col-lg-8 col-md-7 col-sm-12 col-xs-12 ">
                                        <input className="form-control my-0 py-0  lime-border" type="text" onChange={this.handleSearchChange} placeholder="Search" aria-label="Search"></input>
                                        <div className="input-group-append">
                                            <span className="input-group-text gray lighten-2" id="basic-text1"><i className="fas fa-search text-grey"
                                                aria-hidden="true"></i></span>
                                        </div>

                                    </div>
                                    <div className="col-xl-4 col-sm-12 col-xs-12 col-md-5 col-lg-4">
                                        <MDBBtn className=" scrapaddnew " size='md' onClick={this.openAddScrap}><span className="Savedata">Add New </span></MDBBtn>
                                        <MDBBtn className="scrapaddnew" size='md' onClick={this.download} ><span className="Savedata">Export </span></MDBBtn>
                                    </div>

                                </div>
                                <DateRangePickerModal isOpen={this.state.isOpenDateRange} hangleDateRange={this.hangleDateRange} />
                                <MDBRow className="justify-content-left  scraptable">
                                    <MDBCol lg="12"  >

                                        <ReactTable
                                            resizable={false}
                                            minRows={1}
                                            noDataText="NO DATA FOUND"
                                            showPagination={true}
                                            PaginationComponent={Pagination}
                                            defaultPageSize={15}
                                            ref={(r) => this.reactTable = r}
                                            style={{
                                                fontSize: 14
                                                // This will force the table body to overflow and scroll, since there is not enough room
                                            }}
                                            data={this.state.filterScrapData}
                                            columns={columns}

                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                        <APILoader isOpen={this.state.isLoaderOpen} />
                        <APIResponse
                            isOpen={this.state.apiResponseModalOpen}
                            response={this.state.apiResponse}
                            msg={this.state.apiResponseMsg}
                            closeAPIResponse={this.closeAPIResponse}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default Scrappage;