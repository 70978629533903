import React, { Component } from "react";
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import { MDBCol, MDBRow, MDBBtn, MDBInput, MDBBreadcrumb, MDBCard, MDBCardBody, MDBBreadcrumbItem, MDBIcon } from 'mdbreact';
import HttpAPI from '../common/api_calls';
import ApiUtils from '../common/api_utils';
import APIResponse from '../common/APIResponse'
import CustomBreadCrumb from '../pages/sections/CustomBreadCrumb'
import Select from 'react-select';
import './Styleing.css';
import ConfirmMsg from "../common/ConfirmMsg";
import Pagination from "../common/Pagination";
import APILoader from '../common/APILoader'
class TalukaPage extends Component {
    constructor(props) {
        super(props);
        this.state = {

            talukadata: [],
            filterTalukaData: [],
            talukaColumns: [],
            stateData: [],
            countryData: [],
            districtData: [],
            cityData: [],
            filterState: [],
            filterDistrict: [],
            filterCity: [],
            countryId: 0,
            countryName: '',
            stateId: 0,
            stateName: "",
            districtId: 0,
            districtName: "",
            cityId: 0,
            cityName: "",
            confirmMsgOpen: false,
            confirmMsg: '',
            deleteRowId: 0,
            talukaName: "",
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            id: 0,
            selectedcountry: '',
            countryOption: [{ id: 0, name: 'country', label: "", value: "" }],
            selectedstate: '',
            stateOption: [{ id: 0, name: 'state', label: "", value: "" }],
            selecteddistrict: '',
            districtOption: [{ id: 0, name: 'district', label: "", value: "" }],
            selectedcity: '',
            cityoption: [{ id: 0, name: 'city', label: "", value: "" }],
            errorMap: {
                'countryName': { 'valid': true, 'error': '' },
                'stateName': { 'valid': true, 'error': '' },
                'districtName': { 'valid': true, 'error': '' },
                'cityName': { 'valid': true, 'error': '' },
                'talukaName': { 'valid': true, 'error': '' },
            },
            isLoaderOpen: false,
        }
        this.countryInput = React.createRef();
        this.stateInput = React.createRef();
        this.districtInput = React.createRef();
        this.cityInput = React.createRef();
        this.talukaInput = React.createRef();

    }
    componentDidMount() {
        this.renderDataFromServer();
    }
    handleSearchChange = event => {
        this.globalSearch(event.target.value);
    };
    globalSearch = (searchInput) => {
        let filteredData = this.state.talukadata.filter(value => {
            return (
                (value.talukaName !== null && value.talukaName.toLowerCase().includes(searchInput.toLowerCase()))
            );
        });
        this.setState({ filterTalukaData: filteredData });

    }
    async renderDataFromServer() {
        this.setState({
            isLoaderOpen: true
        })

        let countryData = await HttpAPI.getCountry();
        let countryResponse = countryData.data
        if (countryResponse.status === true) {
            this.setState({
                countryData: countryResponse.data
            })
            var arr = []
            arr.push({ id: 0, name: 'country', label: '', value: '' })
            countryResponse.data.map((item, key) =>
                arr.push({
                    id: item.id, name: 'country',
                    label: item.countryName,
                    value: item.countryName
                })
            );
            this.setState({ countryOption: arr })
        }
        let stateData = await HttpAPI.getState()
        let stateResponse = stateData.data
        if (stateResponse.status === true) {
            this.setState({ stateData: stateResponse.data })
        }
        let districtData = await HttpAPI.getDistrict()
        let districtResponse = districtData.data
        if (districtResponse.status === true) {
            this.setState({ districtData: districtResponse.data })
        }
        let cityData = await HttpAPI.getCity()
        let cityResponse = cityData.data
        if (cityResponse.status === true) {
            this.setState({ cityData: cityResponse.data })
            this.populateDefault(cityResponse.data)
        }
        let data = await HttpAPI.getTaluka()
        let dataResponse = data.data
        if (dataResponse.status === true) {
            this.setState({ talukadata: dataResponse.data, filterTalukaData: dataResponse.data })
        }
        this.setState({
            isLoaderOpen: false
        })
    }
    populateDefault(cityData) {
        this.setState({
            selectedcountry: {
                id: this.state.countryData[0].id, name: 'country',
                label: this.state.countryData[0].countryName,
                value: this.state.countryData[0].countryName
            },
            countryId: this.state.countryData[0].id,
            countryName: this.state.countryData[0].countryName

        })
        const data = this.state.stateData.filter(row => row.countryId === this.state.countryData[0].id);
        var arr = []
        arr.push({ id: 0, name: 'state', label: '', value: '' })
        data.map(item => {
            arr.push({ id: item.id, name: 'state', label: item.stateName, value: item.stateName })
        })
        this.setState({
            selectedstate: { id: data[0].id, name: 'state', label: data[0].stateName, value: data[0].stateName },
            stateOption: arr,
            stateId: data[0].id,
            stateName: data[0].stateName
        })
        const districtdata = this.state.districtData.filter(row => row.stateId === data[0].id);
        var arr = []
        arr.push({ id: 0, name: 'district', label: '', value: '' })
        districtdata.map((item, key) =>
            arr.push({
                id: item.id, name: 'district',
                label: item.districtName,
                value: item.districtName
            })
        );
        this.setState({
            districtOption: arr,
            selecteddistrict: {
                id: districtdata[0].id, name: 'district',
                label: districtdata[0].districtName,
                value: districtdata[0].districtName
            },
            districtId: districtdata[0].id,
            districtName: districtdata[0].districtName
        })
        const citydataArr = cityData.filter(row => row.districtId === districtdata[0].id);
        var arr = []
        if(citydataArr && citydataArr.length>0){
            arr.push({ id: 0, name: 'city', label: '', value: '' })
            citydataArr.map(item => {
                arr.push({ id: item.id, name: 'city', label: item.cityName, value: item.cityName })
            })
            this.setState({
                cityoption: arr,
                selectedcity: '',
                selectedcity: { id: citydataArr[0].id, name: 'city', label: citydataArr[0].cityName, value: citydataArr[0].cityName },
                cityId: citydataArr[0].id,
                cityName: citydataArr[0].cityName
            });
    
        }
    }
    closeAPIResponse = () => {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }
    deleteRow = () => {
        this.setState({
            deleteRowId: 0,
            confirmMsgOpen: false,
            confirmMsg: ''
        })
        let id = this.state.deleteRowId
        HttpAPI.deleteTalukaById(id).then(
            response => {
                var response = response.data;
                if (response.status === true) {

                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: 'Taluka Delete Successfully',
                        apiResponseModalOpen: true
                    })
                    this.clearData();
                    this.renderDataFromServer();
                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.closeAPIResponse();
                }, 1000)

            }
        );
    }
    deleteRowById(id) {
        this.setState({
            deleteRowId: id,
            confirmMsgOpen: true,
            confirmMsg: 'Are you sure want to delete?'
        })

    }
    cancelConfirm = () => {
        this.setState({
            deleteRow: '',
            confirmMsgOpen: false,
            confirmMsg: ''

        })
    }
    getRowById(id) {
        HttpAPI.getTalukaById(id).then(
            response => {
                var response = response.data;
                if (response.status === true) {
                    this.setState(
                        {
                            talukaName: response.data.talukaName,
                            countryName: response.data.countryMST.countryName,
                            countryId: response.data.countryId,
                            stateName: response.data.stateMST.stateName,
                            stateId: response.data.stateId,
                            districtName: response.data.districtMST.districtName,
                            districtId: response.data.districtId,
                            cityName: response.data.cityMST.cityName,
                            cityId: response.data.cityId,
                            selectedcountry: { id: response.data.countryId, name: 'country', label: response.data.countryMST.countryName, value: response.data.countryMST.countryName },
                            selectedstate: { id: response.data.stateId, name: 'state', label: response.data.stateMST.stateName, value: response.data.stateMST.stateName },
                            selecteddistrict: { id: response.data.districtId, name: 'district', label: response.data.districtMST.districtName, value: response.data.districtMST.districtName },
                            selectedcity: { id: response.data.cityId, name: 'city', label: response.data.cityMST.cityName, value: response.data.cityMST.cityName },
                            updateRow: response.data,
                            id: response.data.id
                        }
                    );
                    const data = this.state.stateData.filter(row => row.countryId === response.data.countryId);
                    this.setState({
                        filterState: data
                    });
                    const districtdata = this.state.districtData.filter(row => row.stateId === response.data.stateId);
                    this.setState({
                        filterDistrict: districtdata
                    });
                    const city = this.state.cityData.filter(row => row.districtId === response.data.districtId);
                    this.setState({
                        filterCity: city
                    });
                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: false
                    })

                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: false
                    })
                }
                window.setTimeout(() => {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: '',
                        apiResponseModalOpen: false
                    })
                }, 1000)


            }
        );
    }
    changeSelectVar = (e) => {
        this.setState({
            [e.target.name + 'Id']: e.target.options[e.target.selectedIndex].id,
            [e.target.name + 'Name']: e.target.value
        });
    }
    changeSelectVar = (e) => {
        this.setState({
            [e.name + 'Id']: e.id,
            [e.name + 'Name']: e.value,
            ['selected' + e.name]: e,
        });
    }
    changeCountryVar = (e) => {
        var filterId = Number(e.id);
        const data = this.state.stateData.filter(row => row.countryId === filterId);
        var arr = []
        arr.push({ id: 0, name: 'state', label: '', value: '' })
        data.map(item => {
            arr.push({ id: item.id, name: 'state', label: item.stateName, value: item.stateName })
        })
        this.setState({
            stateOption: arr,
            selectedstate: '',
            selecteddistrict: '',
            districtOption: [],

        })
        this.changeSelectVar(e);
    }

    changeStateVar = (e) => {
        var filterId = Number(e.id);
        const districtdata = this.state.districtData.filter(row => row.stateId === filterId);
        var arr = []
        arr.push({ id: 0, name: 'district', label: '', value: '' })
        districtdata.map(item => {
            arr.push({ id: item.id, name: 'district', label: item.districtName, value: item.districtName })
        })
        this.setState({
            districtOption: arr,
            selecteddistrict: ''
        });
        this.changeSelectVar(e);
    }
    changeDistrictVar = (e) => {
        var filterId = Number(e.id);
        const citydata = this.state.cityData.filter(row => row.districtId === filterId);
        var arr = []
        arr.push({ id: 0, name: 'city', label: '', value: '' })
        citydata.map(item => {
            arr.push({ id: item.id, name: 'city', label: item.cityName, value: item.cityName })
        })
        this.setState({
            cityoption: arr,
            selectedcity: ''
        });
        this.changeSelectVar(e);
    }

    setInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    async createTaluka() {
        this.setState({
            isLoaderOpen:true
        })
        let data = {
            'talukaName': this.state.talukaName,
            'countryId': this.state.countryId,
            'stateId': this.state.stateId,
            'districtId': this.state.districtId,
            'cityId': this.state.cityId,
        };
        let senddata = ApiUtils.appendCreateRequiredField(data);
        let talukaData=await HttpAPI.createTaluka(senddata)
        let talukaResponse=talukaData.data
        if (talukaResponse.status === true) {

            this.setState({
                isLoaderOpen:false,
                apiResponse: true,
                apiResponseMsg: 'Taluka insert successfully',
                apiResponseModalOpen: true
            })
            this.clearData();
            this.renderDataFromServer();
        } else {
            this.setState({
                isLoaderOpen:false,
                apiResponse: false,
                apiResponseMsg: talukaResponse.message,
                apiResponseModalOpen: true
            })
        }
        window.setTimeout(() => {
            this.setState({
                apiResponse: false,
                apiResponseMsg: '',
                apiResponseModalOpen: false
            })
        }, 1000)
       
    }
   async UpdateTaluka() {
        this.setState({
            isLoaderOpen:true
        })
        let data = {
            'id': this.state.updateRow.id,
            'talukaName': this.state.talukaName,
            'countryId': this.state.countryId,
            'stateId': this.state.stateId,
            'districtId': this.state.districtId,
            'cityId': this.state.cityId,
            'active': this.state.updateRow.active,
            'createdAt': this.state.updateRow.createdAt,
            'createdBy': this.state.updateRow.createdBy,
        };


        let senddata = ApiUtils.appendUpdateRequiredField(data);
        let updateData=await HttpAPI.UpdateTaluka(senddata);
        let updateResponse=updateData.data
        if (updateResponse.status === true) {
            this.setState({
                isLoaderOpen:false,
                apiResponse: true,
                apiResponseMsg: 'Taluka Update Successfully',
                apiResponseModalOpen: true
            })
            this.clearData();
            this.renderDataFromServer();
        } else {
            this.setState({
                isLoaderOpen:false,
                apiResponse: false,
                apiResponseMsg: updateResponse.message,
                apiResponseModalOpen: true
            })
        }
        window.setTimeout(() => {
            this.setState({
                apiResponse: false,
                apiResponseMsg: '',
                apiResponseModalOpen: false
            })
        }, 1000)
    }
    saveData = () => {
        if (this.validateData() === true) {
            if (this.state.id > 0) {
                this.UpdateTaluka();
            } else {
                this.createTaluka();
            }
        }
    }
    clearData = () => {
        this.setState({
            talukaName: '',
            //countryId: 0,
            //countryName: '',
            //stateId: 0,
            //stateName: '',
            //districtId: 0,
            //districtName: '',
            //cityId: 0,
            //cityName: '',
            //selectedcountry: '',
            //countryOption: [{ id: 0, name: 'country', label: "", value: "" }],
            //selectedstate: '',
            //stateOption: [{ id: 0, name: 'state', label: "", value: "" }],
            //selecteddistrict: '',
            //districtOption: [{ id: 0, name: 'district', label: "", value: "" }],
            //selectedcity: '',
            //cityoption: [{ id: 0, name: 'city', label: "", value: "" }],
            errorMap: {
                'countryName': { 'valid': true, 'error': '' },
                'stateName': { 'valid': true, 'error': '' },
                'districtName': { 'valid': true, 'error': '' },
                'cityName': { 'valid': true, 'error': '' },
                'talukaName': { 'valid': true, 'error': '' },
            }

        })
        this.populateDefault(this.state.cityData)
    }
    cancelClick() {
        this.clearData()
        this.populateDefault(this.state.cityData)
    }
    validateData() {
        let map = {
            'countryName': { 'valid': true, 'error': '' },
            'stateName': { 'valid': true, 'error': '' },
            'districtName': { 'valid': true, 'error': '' },
            'cityName': { 'valid': true, 'error': '' },
            'talukaName': { 'valid': true, 'error': '' },
        }
        let isValid = true;
        if (this.state.countryName === '' || this.state.countryName === 'select country') {
            //this.setState({ apiResponseMsg: 'Please select Country' })
            map.countryName.valid = false;
            map.countryName.error = 'Please select Country'
            this.countryInput.current.focus()
            isValid = false
        }
        if (this.state.stateName === '' || this.state.stateName === 'select state') {
            //this.setState({ apiResponseMsg: 'Please select State' })
            map.stateName.valid = false;
            map.stateName.error = 'Please select State'
            if (isValid === true) {
                this.stateInput.current.focus()
            }
            isValid = false
        }
        if (this.state.districtName === '' || this.state.districtName === 'select district') {
            //this.setState({ apiResponseMsg: 'Please select District' })
            map.districtName.valid = false;
            map.districtName.error = 'Please select District'
            if (isValid === true) {
                this.districtInput.current.focus()
            }
            isValid = false
        }
        if (this.state.cityName === '' || this.state.cityName === 'select city') {
            //this.setState({ apiResponseMsg: 'Please select City' })
            map.cityName.valid = false;
            map.cityName.error = 'Please select City'
            if (isValid === true) {
                this.cityInput.current.focus()
            }
            isValid = false
        }
        if (this.state.talukaName === '') {
            //this.setState({ apiResponseMsg: 'Please fill Taluka Name' })
            map.talukaName.valid = false;
            map.talukaName.error = 'Please fill Taluka Name'
            if (isValid === true) {
                this.talukaInput.current.focus()
            }
            isValid = false
        }
        this.setState({ errorMap: map })
        return isValid;
    }
    render() {
        const columns = [
            {
                id: "1",
                Header: () => (
                    <div className="bgcolor">
                        Taluka Name
                    </div>
                ),
                accessor: "talukaName",
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "2",
                Header: () => (
                    <div className="bgcolor">
                        Actions
                    </div>

                ),
                accessor: "id",
                sortable: false,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Cell: props => {

                    return (
                        <div>
                            <div>
                                <MDBIcon className=" m-2" icon='edit' style={{ cursor: 'pointer' }} onClick={() => this.getRowById(props.value)} />
                                <MDBIcon className=" m-2" icon='trash' style={{ cursor: 'pointer' }} onClick={() => this.deleteRowById(props.value)} />
                            </div>
                        </div>
                    );
                }
            }
        ]
        return (
            <React.Fragment>
                <MDBRow>


                    <div className="col-sm-9 d-flex flex-row-reverse ">
                        <CustomBreadCrumb items={['Master', 'Taluka']} linkTo={['', '']} />
                    </div>
                </MDBRow>
                <MDBRow>
                    <MDBCol lg="3">

                    </MDBCol>
                    <MDBCol lg="6">


                        <MDBCard className="mb-5 mr-0 ">
                            <MDBCardBody className="align-items-center justify-content-between mr-0">
                                <div className="row ">
                                    <div className="form-group col-sm-12  col-xs-12 col-md-3 col-lg-3 col-xl-3">
                                        <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Select Country</span><span style={{ color: 'red' }}> *</span></label>
                                        <Select name="country" ref={this.countryInput} options={this.state.countryOption} value={this.state.selectedcountry} id="select" onChange={this.changeCountryVar} class="browser-default md-select form-control">
                                            <option value="select country">Select </option>
                                            {/* {
                                                    this.state.countryData.map(item => {
                                                        return <option id={item.id} value={item.countryName}>{item.countryName}</option>
                                                    })
                                                }
                                            */}
                                        </Select>
                                        <span className='error-message'>{this.state.errorMap.countryName.valid == true ? '' : this.state.errorMap.countryName.error}</span>
                                    </div>
                                    <div className="form-group col-sm-12 col-xs-12 col-md-3 col-lg-3 col-xl-3 DistrictState">
                                        <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Select State</span><span style={{ color: 'red' }}> *</span></label>
                                        <Select name="state" ref={this.stateInput} options={this.state.stateOption} value={this.state.selectedstate} onChange={this.changeStateVar} id="select" class="browser-default md-select form-control">
                                            <option value="select State">Select </option>
                                            {/* {
                                                    this.state.filterState.map(item => {
                                                        return <option id={item.id} value={item.stateName}>{item.stateName}</option>
                                                    })
                                                }
                                            */}
                                        </Select>
                                        <span className='error-message '>{this.state.errorMap.stateName.valid == true ? '' : this.state.errorMap.stateName.error}</span>
                                    </div>
                                    <div className="form-group col-sm-12 col-xs-12 col-md-3 col-lg-3 col-xl-3 statemargin DistrictState">
                                        <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > <span>Select District</span><span style={{ color: 'red' }}> *</span></label>
                                        <Select name="district" ref={this.districtInput} options={this.state.districtOption} value={this.state.selecteddistrict} id="select" onChange={this.changeDistrictVar} class="browser-default md-select form-control">
                                            <option value="select district">Select </option>
                                            {/*  {
                                                    this.state.filterDistrict.map(item => {
                                                        return <option id={item.id} value={item.districtName}>{item.districtName}</option>
                                                    })
                                                }*/}
                                        </Select>
                                        <span className='error-message'>{this.state.errorMap.districtName.valid == true ? '' : this.state.errorMap.districtName.error}</span>
                                    </div>
                                    <div className="form-group col-sm-12 col-xs-12 col-md-3 col-lg-3 col-xl-3 DistrictState">
                                        <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >  <span>Select City</span><span style={{ color: 'red' }}> *</span></label>
                                        <Select name="city" ref={this.cityInput} options={this.state.cityoption} value={this.state.selectedcity} id="select" onChange={this.changeSelectVar} class="browser-default md-select form-control">
                                            <option value="select city">Select </option>
                                            {/*  {
                                                    this.state.filterCity.map(item => {
                                                        return <option id={item.id} value={item.cityName}>{item.cityName}</option>
                                                    })
                                                } */}

                                        </Select>
                                        <span className='error-message'>{this.state.errorMap.cityName.valid == true ? '' : this.state.errorMap.cityName.error}</span>
                                    </div>







                                </div>



                                <div className="row  ">

                                    <div className="form-group col-sm-12 col-xs-12 col-lg-3 col-md-3 col-xl-3 talukaName ">
                                        <label for="TalukaName" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Taluka Name</span><span style={{ color: 'red' }}> *</span></label>
                                        <input type="text" ref={this.talukaInput} name="talukaName" value={this.state.talukaName} id="TalukaName" className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar}>
                                        </input>
                                        <span className='error-message'>{this.state.errorMap.talukaName.valid == true ? '' : this.state.errorMap.talukaName.error}</span>
                                    </div>
                                    <div className=" form-group col-sm-12 col-xs-12 col-lg-4 col-md-3 col-xl-3    ">
                                        <MDBBtn size='md' onClick={this.saveData} className="talukasavebutton" >
                                            <span className="Savedata ">
                                                Add New
                                            </span>
                                        </MDBBtn>
                                    </div>
                                    <div className=" form-group col-sm-12 col-xs-12 col-lg-4 col-md-3 col-xl-3 ">
                                        <MDBBtn size='md' onClick={this.clearData} className="talukacancelbutton">
                                            <span className="Savedata">
                                                Cancel
                                            </span>
                                        </MDBBtn>
                                    </div>

                                </div>

                                <div className="input-group md-form form-sm form-2 pl-0 countrysearchicon">
                                    <input className="form-control my-0 py-0  lime-border" type="text" onChange={this.handleSearchChange} placeholder="Search" aria-label="Search"></input>
                                    <div className="input-group-append">
                                        <span className="input-group-text gray lighten-2" id="basic-text1"><i className="fas fa-search text-grey"
                                            aria-hidden="true"></i></span>
                                    </div>
                                </div>
                                <MDBRow className="justify-content-left districttable" >
                                    <MDBCol lg="12" className="mb-2">
                                        <ReactTable
                                            resizable={false}
                                            minRows={1}
                                            noDataText="NO DATA FOUND"
                                            showPagination={true}
                                            PaginationComponent={Pagination}
                                            defaultPageSize={15}
                                            style={{
                                                fontSize: 14
                                                // This will force the table body to overflow and scroll, since there is not enough room
                                            }}
                                            data={this.state.filterTalukaData}
                                            columns={columns} />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <APILoader isOpen={this.state.isLoaderOpen} />
                    <APIResponse
                        isOpen={this.state.apiResponseModalOpen}
                        response={this.state.apiResponse}
                        msg={this.state.apiResponseMsg}
                        closeAPIResponse={this.closeAPIResponse}
                    />
                    <ConfirmMsg
                        isOpen={this.state.confirmMsgOpen}
                        msg={this.state.confirmMsg}
                        yesMethod={this.deleteRow}
                        noMethod={this.cancelConfirm}
                    />
                    <MDBCol lg="3">

                    </MDBCol>
                </MDBRow>
            </React.Fragment >



        );
    }
}
export default TalukaPage;