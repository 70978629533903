import React, { Component } from "react";
import { MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn } from "mdbreact";
import * as moment from 'moment';
import HttpAPI from '../../common/api_calls';
import ApiUtils from '../../common/api_utils';


class RCDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: this.props.isOpen,
            record: this.props.RCRecord,
            purchaseData: [],
        }
    }
    closeModal = () => {
        this.setState({
            openModal: false
        });

    }
    componentWillReceiveProps(newProps) {
        this.setState({
            openModal: newProps.isOpen,
            record: newProps.RCRecord,
        })
        if(newProps.isOpen==true){
            HttpAPI.getPurchaseByRCId(this.state.record.id).then(

                response=>{
                    var response = response.data;
                    console.log(response);
                    this.setState({
                        purchaseData:response.data
                    })
                }
            )
        }
        
    }
    
    render() {
        return (<div>
            <MDBModal isOpen={this.state.openModal} size='lg'>
                <MDBModalHeader >RC Detail</MDBModalHeader>
                <MDBModalBody>
                    <MDBRow className="justify-content-left ml-0 mx-auto">
                        <MDBCol lg='3' className=''>
                            <MDBRow className="justify-content-left ml-0 mx-auto">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold  '>Purchase No  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{this.state.record.purchaseNumber}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3' className=''>
                            <MDBRow  className="purchasedetailformodelyear ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>RC Book ID  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small rcbookid'>{this.state.record.rcBookId}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3' className=''>
                            <MDBRow className="purchasedetailformodelyear ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Stock  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small stockdata'>{this.state.record.stock}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchasedetailformodelyear ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Company</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{this.state.purchaseData.companyMST == null ? '' : this.state.purchaseData.companyMST.description}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>

                    </MDBRow>
                    <MDBRow className="justify-content-left ml-0 mx-auto">
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-0 mx-auto">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                <label className='small font-weight-bold '>Model  :-</label>
                            </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{this.state.purchaseData.modelMST == null ? '' : this.state.purchaseData.modelMST.description}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchasedetailformodelyear ml-2">
                                <MDBCol size='3' lg='8' className='m-0 p-0'>
                                <label className='small font-weight-bold '>Customer Name  :-</label>
                            </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{this.state.purchaseData.contactMST == null ? '' : this.state.purchaseData.contactMST.firstName}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchasedetailformodelyear ml-2">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                <label className='small font-weight-bold '>Mobile No  :-</label>
                            </MDBCol>
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small '>{this.state.purchaseData.contactMST == null ? '' : this.state.purchaseData.contactMST.mobileNumber}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow  className="purchasedetailformodelyear ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                <label className='small font-weight-bold '>RC Received Date </label>
                            </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{moment(this.state.record.rcRecvdDate).format('DD/MM/YYYY')}</label>

                                </MDBCol>
                            </MDBRow>
                        </MDBCol>

                    </MDBRow>
                    <MDBRow className="justify-content-left ml-0 mx-auto">
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-0 mx-auto">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                <label className='small font-weight-bold '>Loan Detail  :-</label>
                            </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{this.state.purchaseData.loanAccountNo}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchasedetailformodelyear ml-2">
                                <MDBCol size='3' lg='8' className='m-0 p-0'>
                                <label className='small font-weight-bold '>All Doc Received  :-</label>
                            </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{this.state.record.allDocsReceived==true?'Yes':'No'}</label>

                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchasedetailformodelyear ml-2">
                                <MDBCol size='3' lg='9' className='m-0 p-0'>
                                <label className='small font-weight-bold '>ID Proof Received  :-</label>
                            </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{this.state.record.idProofReceived==true?'Yes':'No'}</label>

                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchasedetailformodelyear ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                <label className='small font-weight-bold '>Is Submitted  :-</label>
                            </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{this.state.record.isSubmitted==true?'Yes':'No'}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>


                    </MDBRow  >
                    <MDBRow  className="justify-content-left ml-0 mx-auto">
                    <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-0 mx-auto">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                <label className='small font-weight-bold '>Bike No  :-</label>
                            </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{this.state.purchaseData.bikeNumber}</label>

                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchasedetailformodelyear ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                <label className='small font-weight-bold '>Engine No  :-</label>
                            </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{this.state.purchaseData.engineNumber}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchasedetailformodelyear ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                <label className='small font-weight-bold '>Chasis No  :-</label>
                            </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{this.state.purchaseData.chasisNumber}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchasedetailformodelyear ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                <label className='small font-weight-bold '>RTO Detail  :-</label>
                            </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{this.state.purchaseData.rtoDetail}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="secondary" onClick={this.closeModal} >Close</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        </div>)
    }
}
export default RCDetail