import React, { Component } from "react";
import { MDBTabContent, MDBFormInline, MDBIcon, MDBContainer, MDBNavItem, MDBNavLink, MDBNav, MDBRow, MDBBtn, MDBInput, MDBBreadcrumb, MDBCard, MDBCardBody, MDBBreadcrumbItem, MDBCol, MDBCollapse } from 'mdbreact';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import HttpAPI from '../common/api_calls';
import ApiUtils from '../common/api_utils';
import DateRangePickerModal from '../common/DateRangePickerModal'
import SellDetail from './modal/SellDetail'
import PurchaseDetail from './modal/PurchaseDetail'
import * as moment from 'moment';
import CustomBreadCrumb from '../pages/sections/CustomBreadCrumb'
import Pagination from "../common/Pagination";
import Select from 'react-select';
import APIResponse from '../common/APIResponse'
import "./Styleing.css";
import ConfirmMsg from "../common/ConfirmMsg";
import ReactToPrint from 'react-to-print';
import SellKarar from '../pages/karar/SellKarar'
import APILoader from '../common/APILoader'
import ClearDueAmount from '../common/ClearDueAmount'
moment.locale('en')

class SellPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sellData: [],
            customerData: [],
            selectedsell: {},
            openModal: false,
            filterSellData: [],
            startDate: null,
            endDate: null,
            dateLabel: '',
            sellNumber: '',
            contactId: 0,
            rcSubmit: '',
            selectedsellNumber: '',
            selectedcontact: '',
            selectedrcSubmit: '',
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            sellNumberOption: [{ name: 'sellNumber', label: '', value: '' }],
            contactOption: [{ name: 'contact', label: '', value: '' }],
            rcSubmitOption: [{ name: 'rcSubmit', label: 'Yes', value: 'Yes' }, { name: 'rcSubmit', label: 'No', value: 'No' }],
            confirmMsgOpen: false,
            clearDueMsgOpen:false,
            confirmMsg: '',
            deleteRowId: 0,
            loadingIsOpen: false,
            clearDueDisable: true,
            confirmType: '',
        }
        this.componentRef = React.createRef();
        this.clickPdf = React.createRef();
        this.clickPrint = React.createRef();

    }
    componentDidMount() {
        this.renderDataFromServer()
    }
    setSelectInputVar = (e) => {
        this.setState({
            [e.name]: e.value,
            ['selected' + e.name]: e
        });
    }
    setVendorInputVar = (e) => {
        this.setState({
            [e.name + 'Id']: e.id,
            ['selected' + e.name]: e
        });
    }
    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
    async renderDataFromServer() {
        this.setState({loadingIsOpen:true})
        HttpAPI.getContact().then(
            response => {
                var response = response.data;
                var arr = []
                arr.push({ id: 0, name: 'contact', label: '', value: '' })
                response.data.map((item, key) =>
                    arr.push({
                        id: item.id, name: 'contact',
                        label: item.contactType + '-' + item.firstName + ' ' + item.lastName,
                        value: item.contactType + '-' + item.firstName + ' ' + item.lastName
                    })
                );
                this.setState({ customerData: response.data, contactOption: arr });
            })
        let sellData = await HttpAPI.getSellData()
        // HttpAPI.getSellData().then(
        // response => {
        //     var response = response.data;
        //     if (response.status === true) {
        //         this.setState({
        //             sellData: response.data,
        //             filterSellData: response.data
        //         })
        //         let sellCodeArr = []

        //         response.data
        //             .map(row => {

        //                 sellCodeArr.push({ name: 'sellNumber', label: row.sellCode, value: row.sellCode })

        //             })

        //         this.setState({ sellNumberOption: sellCodeArr })

        //     }
        // })
        var sellResponse = sellData.data
        if (sellResponse.status === true) {
            this.setState({
                sellData: sellResponse.data,
                filterSellData: sellResponse.data
            })
            let sellCodeArr = []
            sellResponse.data
                .map(row => {

                    sellCodeArr.push({ name: 'sellNumber', label: row.sellCode, value: row.sellCode })

                })

            this.setState({ sellNumberOption: sellCodeArr })
        }
        this.setState({loadingIsOpen:false})
    }
    onToggleDateControl = () => {
        this.setState({ isOpenDateRange: true })
    }
    hangleDateRange = (selectedStartDate, selectedEndDate) => {
        if (selectedStartDate != null && selectedEndDate != null) {

            let dt = new Date(selectedStartDate)
            let dt2 = new Date(selectedEndDate)

            this.setState({
                startDate: dt.setHours(0, 0, 0, 0),
                endDate: dt2.setHours(23, 59, 59, 0),
                isOpenDateRange: false,
                dateLabel: moment(selectedStartDate).format('DD/MM/YYYY').toString() + '-' + moment(selectedEndDate).format('DD/MM/YYYY').toString()
            })
        } else {
            this.setState({
                startDate: null,
                endDate: null,
                isOpenDateRange: false,
                dateLabel: ''
            })
        }

    }
    handleSearchChange = event => {
        this.globalSearch(event.target.value);
    };
    openAddSell = () => {
        window.localStorage.setItem('editsellId', 0)
        window.localStorage.setItem('inventorySell',0)
        window.location.href = '/addSell'
    }
    editSell = (id) => {
        window.localStorage.setItem('editsellId', id)
        window.location.href = '/editSell'
    }
    viewSell = (id) => {
        console.log("button clicked");
        HttpAPI.getSellById(id).then(
            response => {
                var response = response.data;

                this.setState({ selectedsell: response.data })
                this.setState({ openModal: true })

            }
        );
    }
    async filterClick(){
        this.setState({
            loadingIsOpen:true
        })
        let criteria = ''
        if (this.state.sellNumber !== '') {
            criteria = criteria + 'sell_code=' + this.state.sellNumber
        }
        if (this.state.contactId > 0) {
            criteria = criteria + '&customer_id=' + this.state.contactId
        }
        if (this.state.rcSubmit !== '') {
            if (this.state.rcSubmit === 'Yes') {
                criteria = criteria + '&rc_given=true'
            } else {
                criteria = criteria + '&rc_given=false'
            }
        }

        var tzoffset = (new Date()).getTimezoneOffset() * 60000;

        if (this.state.startDate !== null) {
            criteria = criteria + "&sell_from=" + (new Date(this.state.startDate)).toISOString()
        }
        if (this.state.endDate !== null) {
            criteria = criteria + "&sell_to=" + (new Date(this.state.endDate)).toISOString()
        }
        if (criteria !== '') {
            let filterData=await HttpAPI.getSellFilter(criteria)
            let filterResponse=filterData.data
            if(filterResponse.status===true){
                var data = filterResponse.data;
                if (this.state.contactId > 0) {
                    if (data.length > 0) {
                        this.setState({
                            clearDueDisable: false
                        })
                    }
                }
                this.setState({
                    sellData: data,
                    filterSellData: data
                })
            }else{
                this.setState({
                    sellData: [],
                    filterSellData: []
                })
            }
            
        }
        this.setState({
            loadingIsOpen:false
        })
    }
    searchClick = () => {
        this.filterClick()
    }
    addReceipt = (props) => {
        window.localStorage.setItem('editReceiptId', 0)
        window.localStorage.setItem('receiptSellId', props.original.id);
        window.location.href = '/addReceipt'
    }
    clearSearch = () => {
        this.setState({
            startDate: null,
            endDate: null,
            dateLabel: '',
            sellNumber: '',
            contactId: 0,
            rcSubmit: '',
            selectedsellNumber: '',
            selectedcontact: '',
            selectedrcSubmit: '',
            clearDueDisable: true,
            confirmType: '',
        })
        this.renderDataFromServer()
    }
    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
    }
    closeSellDetail = () => {
        this.setState({ selectedsell: {} })
        this.setState({ openModal: false })
    }
    deleteRowById = (id) => {
        this.setState({
            deleteRowId: id,
            confirmMsgOpen: true,
            clearDueMsgOpen:false,
            confirmMsg: 'Are you sure want to delete?'
        })

    }
    async generateKarar(row) {
        let sellRow = row.original
        let loginId = window.localStorage.getItem('loginUserId')
        let voucherData = await HttpAPI.getReceiptBySellId(row.original.id)
        let userData = await HttpAPI.getUMById(loginId)

        var partyVoucherResponse = voucherData.data
        var userMgmtResponse = userData.data

        if (userMgmtResponse.status === true) {
            let data = userMgmtResponse.data
            let UMData = { 'userMaster': data }
            sellRow = Object.assign(sellRow, UMData)
        }
        if (partyVoucherResponse.status === true) {
            let data = partyVoucherResponse.data
            if (data.length > 0) {
                let voucherRecord = data[data.length - 1]
                let voucherdata = { 'voucherDate': voucherRecord.date }
                sellRow = Object.assign(sellRow, voucherdata)
            }

        }
        this.componentRef.fillReport(sellRow)
        await this.sleep(1000);
        this.clickPrint.click()

    }
    confirmClearDue = () => {
        this.setState({
            confirmMsgOpen: false,
            clearDueMsgOpen: true,
            confirmType: 'clear due'
        })
    }
    clearDuePayment = (amount) => {
        this.setState({
            confirmMsgOpen: false,
            clearDueMsgOpen:false,
            confirmMsg: '',
            confirmType: ''
        })
        HttpAPI.clearSalesDuePayment(this.state.contactId,amount).then(response => {
            var response = response.data
            if (response.status === true) {
                this.searchClick()
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: response.message,
                    apiResponseModalOpen: true
                })
                window.setTimeout(() => {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: '',
                        apiResponseModalOpen: false
                    })
                }, 2000)
            } else {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: response.message,
                    apiResponseModalOpen: true
                })
                window.setTimeout(() => {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: '',
                        apiResponseModalOpen: false
                    })
                }, 2000)
            }
        })
    }
    closeAPIResponse = () => {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }
    deleteRow = () => {
        let id = this.state.deleteRowId
        this.setState({
            deleteRowId: 0,
            confirmMsgOpen: false,
            clearDueMsgOpen:false,
            confirmMsg: ''
        })
        HttpAPI.deleteSellById(id).then(
            response => {
                var response = response.data;
                if (response.status === true) {
                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: 'Sales Delete Successfully',
                        apiResponseModalOpen: true
                    })
                    this.renderDataFromServer();
                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: '',
                        apiResponseModalOpen: false
                    })
                }, 1000)

            });
    }
    cancelConfirm = () => {
        this.setState({
            deleteRowId: 0,
            confirmMsgOpen: false,
            clearDueMsgOpen:false,
            confirmMsg: '',
            confirmType: '',
        })
    }
    globalSearch = (searchInput) => {
        let filteredData = this.state.sellData.filter(value => {
            return (
                (value.sellDate !== null && moment(value.sellDate).format('DD/MMM/YYYY').toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.purchaseMST !== null && value.purchaseMST.purchaseNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.sellCode !== null && value.sellCode.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.sellFor !== null && value.sellFor.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.purchaseMST !== null && value.purchaseMST.companyMST.description.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.purchaseMST !== null && value.purchaseMST.modelMST.description.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.purchaseMST !== null && value.purchaseMST.bikeNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.contactMST !== null && value.contactMST.firstName.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.contactMST !== null && value.contactMST.mobileNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.amount !== null && value.amount.toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.recievedAmount !== null && value.recievedAmount.toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.installments !== null && value.installments.toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                ((value.purchaseMST !== null && value.purchaseMST.rcBookReceived !== null) && (value.purchaseMST.rcBookReceived === true ? 'Yes' : 'No').toLowerCase().includes(searchInput.toLowerCase())) ||
                ((value.purchaseMST !== null && value.purchaseMST.rcMST !== null) && (value.purchaseMST.rcMST.submitted === true ? 'Yes' : 'No').toLowerCase().includes(searchInput.toLowerCase()))

            );
        });
        this.setState({ filterSellData: filteredData });
    }
    render() {
        const columns = [
            {
                id: "1",
                Header: () => (
                    <div className="bgcolor">
                        Sr No.
                    </div>
                ),
                accessor: 'id',
                width: 100,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Footer: row => {
                    let arrSum = row.data.
                        map(maprow => maprow._original);
                    const sum = arrSum.length
                    return <div><span style={{ 'font-weight': 'bold','font-size':'12px' }} title={"Total-"+sum}>{"Total-"+sum}</span> </div>;
                },
                getFooterProps: () => {
                    return {
                        style: {
                            background: "#bdbdbd"
                        }
                    }
                }
            },
            {
                id: "2",
                Header: () => (
                    <div className="bgcolor">
                        Date
                    </div>
                ),
                accessor: d => moment(d.sellDate).format('DD/MMM/YYYY'),
                width: 100,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            /*
            {
                id: "2",
                Header: () => (
                    <div className="bgcolor">
                        Purchase Code
                    </div>
                ),
                accessor: 'purchaseMST.purchaseNumber',
                width: 150,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            */
            {
                id: "3",
                Header: () => (
                    <div className="bgcolor">
                        Sales Code
                    </div>
                ),
                accessor: 'sellCode',
                width: 120,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Cell: props => {
                    return (
                        <div>

                            <span title={props.value}>{props.value}</span>
                        </div>
                    )
                }

            },
            {
                id: "4",
                Header: () => (
                    <div className="bgcolor">
                        Sales For
                    </div>
                ),
                accessor: 'sellFor',
                width: 80,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            /*
            {
                id: "5",
                Header: () => (
                    <div className="bgcolor">
                        Company
                    </div>
                ),
                accessor: 'purchaseMST.companyMST.description',
                width: 150,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            */
            {
                id: "6",
                Header: () => (
                    <div className="bgcolor">
                        Model
                    </div>
                ),
                accessor: 'purchaseMST.modelMST.description',
                width: 80,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Cell: props => {
                    return (
                        <div>

                            <span title={props.value}>{props.value}</span>
                        </div>
                    )
                }

            },
            {
                id: "7",
                Header: () => (
                    <div className="bgcolor">
                        Bike No
                    </div>
                ),
                accessor: 'purchaseMST.bikeNumber',
                width: 120,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Cell: props => {
                    return (
                        <div>

                            <span title={props.value}>{props.value}</span>
                        </div>
                    )
                }

            },
            {
                id: "8",
                Header: () => (
                    <div className="bgcolor">
                        Vendor Name
                    </div>
                ),
                accessor: 'contactMST.firstName',
                width: 105,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Cell: props => {
                    return (
                        <div>

                            <span title={props.value}>{props.value}</span>
                        </div>
                    )
                }

            },
            /*
            {
                id: "9",
                Header: () => (
                    <div className="bgcolor">
                        Vendor Number
                    </div>
                ),
                accessor: 'contactMST.mobileNumber',
                width: 150,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            */
            {
                id: "10",
                Header: () => (
                    <div className="bgcolor">
                        Total Amt
                    </div>
                ),
                accessor: 'amount',
                width: 80,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Footer: row => {
                    let arrSum = row.data.
                        map(maprow => maprow._original);
                    const sum = arrSum
                        .map(d => d.amount)
                        .reduce((a, b) => a + b, 0);
                    return <div><span style={{ 'font-weight': 'bold' }} title={ApiUtils.getNumericFormat(sum)}>{ApiUtils.getNumericFormat(sum)}</span> </div>;
                },
                getFooterProps: () => {
                    return {
                        style: {
                            background: "#bdbdbd"
                        }
                    }
                }
            },
            {
                id: "11",
                Header: () => (
                    <div className="bgcolor">
                        Paid Amt
                    </div>
                ),
                accessor: 'recievedAmount',
                width: 80,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Footer: row => {
                    let arrSum = row.data.
                        map(maprow => maprow._original);
                    const sum = arrSum
                        .map(d => d.recievedAmount)
                        .reduce((a, b) => a + b, 0);
                    return <div><span style={{ 'font-weight': 'bold' }} title={ApiUtils.getNumericFormat(sum)}>{ApiUtils.getNumericFormat(sum)}</span> </div>;
                },
                getFooterProps: () => {
                    return {
                        style: {
                            background: "#bdbdbd"
                        }
                    }
                }
            },
            {
                id: "12",
                Header: () => (
                    <div className="bgcolor">
                        Due Amt
                    </div>
                ),
                accessor: d => Number(d.amount) - Number(d.recievedAmount),
                width: 85,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Footer: row => {
                    let arrSum = row.data.
                        map(maprow => maprow._original);
                    const sum = arrSum
                        .map(d => Number(d.amount) - Number(d.recievedAmount))
                        .reduce((a, b) => a + b, 0);
                    return <div><span style={{ 'font-weight': 'bold' }} title={ApiUtils.getNumericFormat(sum)}>{ApiUtils.getNumericFormat(sum)}</span> </div>;
                },
                getFooterProps: () => {
                    return {
                        style: {
                            background: "#bdbdbd"
                        }
                    }
                }
            },
            {
                id: "13",
                Header: () => (
                    <div className="bgcolor">
                        EMI
                    </div>
                ),
                accessor: 'installments',
                width: 60,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "14",
                Header: () => (
                    <div className="bgcolor">
                        RC Received
                    </div>
                ),
                accessor: d => d.purchaseMST.rcBookReceived === null ? '' : d.purchaseMST.rcBookReceived === true ? 'Yes' : 'No',
                width: 90,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
            },
            {
                id: "15",
                Header: () => (
                    <div className="bgcolor">
                        RC Submitted
                    </div>
                ),
                accessor: d => d.purchaseMST.rcMST === null ? 'No' : d.purchaseMST.rcMST.submitted === true ? 'Yes' : 'No',
                width: 90,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
            },
            {
                id: "16",
                Header: () => (
                    <div className="bgcolor">
                        Receipt
                    </div>
                ),

                sortable: false,
                width: 70,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Cell: props => {

                    return (
                        <div>
                            <div>
                                <MDBIcon className=" m-2" icon='book' style={{ cursor: 'pointer' }} onClick={() => this.addReceipt(props)} />
                            </div>
                        </div>
                    );
                }
            },
            {
                id: "17",

                Header: () => (
                    <div className="bgcolor">
                        KARAR
                    </div>
                ),
                sortable: false,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                width: 65,
                Cell: props => {

                    return (
                        <div>
                            <div>
                                <MDBIcon className=" m-2" icon='book' style={{ cursor: 'pointer' }} onClick={() => this.generateKarar(props)} />
                            </div>
                        </div>
                    );
                }
            },
            {
                id: "18",
                Header: () => (
                    <div className="bgcolor">
                        Actions
                    </div>
                ),
                accessor: "id",
                width: 160,
                sortable: false,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Cell: props => {

                    return (
                        <div>
                            <div>
                                <MDBIcon className=" m-2" icon='eye' style={{ cursor: 'pointer' }} onClick={() => this.viewSell(props.value)} />
                                <MDBIcon className=" m-2" icon='edit' style={{ cursor: 'pointer' }} onClick={() => this.editSell(props.value)} />
                                <MDBIcon className=" m-2" icon='trash' style={{ cursor: 'pointer' }} onClick={() => this.deleteRowById(props.value)} />
                            </div>
                        </div>
                    );
                }
            }
        ]
        return (
            <div>
                <MDBRow>

                    <div className="col-sm-12 d-flex flex-row-reverse">
                        <CustomBreadCrumb items={['Sales', 'Sales']} linkTo={['', '']} />
                    </div>
                </MDBRow>
                <MDBRow>
                    <MDBCol lg="12">
                        <MDBCard className="mb-6 mr-0 " >
                            <MDBCardBody className="align-items-center justify-content-between mr-0">
                                <div className="row ">
                                    <div className="form-group col-xl-5 col-lg-5 col-md-5">

                                    </div>
                                    <div className="col-xl-6 col-lg-5 col-md-6">
                                        <MDBBtn className="Filterbutton" size="sm" onClick={this.toggleCollapse('filterdata')}>
                                            <span className="Savedata ">
                                                ADD Filter
                                            </span>
                                        </MDBBtn>

                                    </div>

                                </div>
                                <MDBCollapse id='filterdata' isOpen={this.state.collapseID}>
                                    <div className="row">
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 ">
                                            <label for="sell" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Sales Number</label>
                                            <Select name="sellNumber" options={this.state.sellNumberOption} value={this.state.selectedsellNumber} onChange={this.setSelectInputVar} id="sell" class="input-field browser-default md-select form-control form-control-sm" >
                                            </Select>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 purchasefor">
                                            <label for="vendor" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Vendor</label>
                                            <Select name="contact" options={this.state.contactOption} value={this.state.selectedcontact} onChange={this.setVendorInputVar} id="vendor" class="input-field browser-default md-select form-control form-control-sm" >
                                            </Select>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 purchasefor ">
                                            <label for="rcSubmit" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >RC Submmited</label>
                                            <Select name="rcSubmit" options={this.state.rcSubmitOption} value={this.state.selectedrcSubmit} onChange={this.setSelectInputVar} id="rcSubmit" class="input-field browser-default md-select form-control form-control-sm" >
                                            </Select>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 purchasefor ">
                                            <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Date Range</label>
                                            <input
                                                value={this.state.dateLabel} onClick={this.onToggleDateControl} className="form-control" />
                                        </div>
                                        <div className="form-group col-xl-1 col-lg-2 col-md-1 col-sm-12 col-xs-12 ">
                                            <MDBBtn className="  salessave btn-md " size='md' onClick={this.searchClick}><i className="fas fa-filter"></i><span className="Savedata pl-1 ">Apply filter</span></MDBBtn>
                                        </div>
                                        <div className="form-group col-xl-1 col-lg-2 col-md-1 ml-2 col-sm-12 col-xs-12">
                                            <MDBBtn className=" salescancel  " size='md' onClick={this.clearSearch}><i className="fas fa-undo-alt"></i><span className="Savedata pl-1">  Clear Filter</span></MDBBtn>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <MDBBtn disabled={this.state.clearDueDisable} className="salesclearDue" size='md' onClick={this.confirmClearDue}><i className="fas fa-clear"></i><span className="Savedata pl-1 ">Clear Due</span></MDBBtn>
                                        </div>
                                        <DateRangePickerModal isOpen={this.state.isOpenDateRange} hangleDateRange={this.hangleDateRange} />
                                    </div>
                                </MDBCollapse>

                                <div className="row purchasesearchicon">
                                    <div className="input-group md-form form-sm form-2 col-xl-11 col-lg-10 col-md-9 col-sm-12 col-xs-12 ">
                                        <input className="form-control my-0 py-0  lime-border" type="text" onChange={this.handleSearchChange} placeholder="Search" aria-label="Search"></input>
                                        <div className="input-group-append">
                                            <span className="input-group-text gray lighten-2" id="basic-text1"><i className="fas fa-search text-grey"
                                                aria-hidden="true"></i></span>
                                        </div>

                                    </div>

                                    <div className="form-group col-xl-1 col-lg-2 col-md-2 col-sm-12 col-xs-12 ">
                                        <MDBBtn className="mt-3 purchaseaddnew" size='md' onClick={this.openAddSell}><span className="Savedata">Add New </span></MDBBtn>
                                    </div>
                                </div>
                                <MDBRow className="justify-content-left   ">
                                    <MDBCol lg="12"  >
                                        <ReactTable
                                            resizable={false}
                                            minRows={1}
                                            noDataText="NO DATA FOUND"
                                            showPagination={true}
                                            PaginationComponent={Pagination}
                                            defaultPageSize={25}

                                            style={{
                                                fontSize: 14
                                                // This will force the table body to overflow and scroll, since there is not enough room
                                            }}
                                            data={this.state.filterSellData}
                                            columns={columns} />
                                    </MDBCol>
                                    <APIResponse
                                        isOpen={this.state.apiResponseModalOpen}
                                        response={this.state.apiResponse}
                                        msg={this.state.apiResponseMsg}
                                        closeAPIResponse={this.closeAPIResponse}
                                    />
                                    <ConfirmMsg
                                        isOpen={this.state.confirmMsgOpen}
                                        msg={this.state.confirmMsg}
                                        yesMethod={this.deleteRow}
                                        noMethod={this.cancelConfirm}
                                    />
                                     <ClearDueAmount 
                                            isOpen={this.state.clearDueMsgOpen}
                                            yesMethod={this.clearDuePayment}
                                            noMethod={this.cancelConfirm}
                                    />
                                </MDBRow>
                                <div style={{ 'display': 'none' }}>
                                    <ReactToPrint
                                        trigger={() => { return <a href="#" ref={el => this.clickPrint = el}>Print this out!</a>; }}
                                        content={() => this.componentRef}
                                    />
                                    <SellKarar onRef={el => this.componentRef = el} />
                                </div>
                                <SellDetail isOpen={this.state.openModal} SellRecord={this.state.selectedsell} closeSellDetail={this.closeSellDetail} />
                                <APILoader isOpen={this.state.loadingIsOpen} />
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>

                </MDBRow>
            </div>
        );
    }
}
export default SellPage