import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import ApiUtils from "./api_utils.js";

var HttpAPI = {
  /*Dev URLs */
  //API_BASE_URL: "http://localhost:8080/AIMSRestAPI",
  //API_BASE_URL: "https://tomcat.harvices.com/aims-uat/AIMSRestAPI",

  /*Prod URLs */
  //API_BASE_URL: 'https://tomcat.harvices.com/aims/AIMSRestAPI',
  //API_BASE_URL: "https://tomcat.harvices.com/aims-salimauto/AIMSRestAPI",
  //API_BASE_URL: "https://tomcat.harvices.com/aims-chamunda/AIMSRestAPI",
  //API_BASE_URL: "https://tomcat.harvices.com/aims-superauto/AIMSRestAPI",
  //API_BASE_URL: "https://tomcat.harvices.com/aims-zaveriauto/AIMSRestAPI",

  //API_BASE_URL: 'https://projects.harvices.in/aims/AIMSRestAPI',
  //API_BASE_URL: "https://projects.harvices.in/aims-salimauto/AIMSRestAPI",
  API_BASE_URL: "https://projects.harvices.in/aims-chamunda/AIMSRestAPI",
  //API_BASE_URL: "https://projects.harvices.in/aims-superauto/AIMSRestAPI",
  //API_BASE_URL: "https://projects.harvices.in/aims-zaveriauto/AIMSRestAPI",
  getLogin: function (data) {
    var url = this.API_BASE_URL + "/userMST/login";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  createReminderPref: function (data) {
    var url = this.API_BASE_URL + "/reminderPreference/create";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  createCountry: function (data) {
    var url = this.API_BASE_URL + "/countryMST/create";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  UpdateReminderPref: function (data) {
    var url = this.API_BASE_URL + "/reminderPreference/update";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .put(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  UpdateReminder: function (data) {
    var url = this.API_BASE_URL + "/reminderMST/update";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .put(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  UpdateCountry: function (data) {
    var url = this.API_BASE_URL + "/countryMST/update";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .put(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  createState: function (data) {
    var url = this.API_BASE_URL + "/stateMST/create";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  UpdateState: function (data) {
    var url = this.API_BASE_URL + "/stateMST/update";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .put(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  createDistrict: function (data) {
    var url = this.API_BASE_URL + "/districtMST/create";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  UpdateDistrict: function (data) {
    var url = this.API_BASE_URL + "/districtMST/update";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .put(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  createCity: function (data) {
    var url = this.API_BASE_URL + "/cityMST/create";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  UpdateCity: function (data) {
    var url = this.API_BASE_URL + "/cityMST/update";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .put(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  createTaluka: function (data) {
    var url = this.API_BASE_URL + "/talukaMST/create";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  UpdateTaluka: function (data) {
    var url = this.API_BASE_URL + "/talukaMST/update";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .put(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  createCompany: function (data) {
    var url = this.API_BASE_URL + "/companyMST/create";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  UpdateCompany: function (data) {
    var url = this.API_BASE_URL + "/companyMST/update";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .put(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  createModel: function (data) {
    var url = this.API_BASE_URL + "/modelMST/create";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  UpdateModel: function (data) {
    var url = this.API_BASE_URL + "/modelMST/update";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .put(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  createVehicleCategory: function (data) {
    var url = this.API_BASE_URL + "/modelCategotyMST/create";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  UpdateVehicleCategory: function (data) {
    var url = this.API_BASE_URL + "/modelCategotyMST/update";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .put(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },

  createContact: function (data) {
    var url = this.API_BASE_URL + "/contactMST/create";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  createAddress: function (data) {
    var url = this.API_BASE_URL + "/addressMST/createALL";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  createAddressType: function (data) {
    var url = this.API_BASE_URL + "/addressTypeMST/create";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  createRC: function (data) {
    var url = this.API_BASE_URL + "/rcMST/create";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  UpdateRC: function (data) {
    var url = this.API_BASE_URL + "/rcMST/update";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .put(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },

  createUM: function (data) {
    var url = this.API_BASE_URL + "/userMST/create";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  UpdateUM: function (data) {
    var url = this.API_BASE_URL + "/userMST/update";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .put(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  createPurchase: function (data) {
    var url = this.API_BASE_URL + "/purchaseMST/create";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  createSell: function (data) {
    var url = this.API_BASE_URL + "/sellMST/create";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  createPartyVoucher: function (data) {
    var url = this.API_BASE_URL + "/partyVoucher/create";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  createReceipt: function (data) {
    var url = this.API_BASE_URL + "/sellReceipt/create";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  createExpense: function (data) {
    var url = this.API_BASE_URL + "/miscMST/create";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  createScrap: function (data) {
    var url = this.API_BASE_URL + "/scrapMST/create";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  createUrgentReminder: function (data) {
    var url = this.API_BASE_URL + "/reminderMST/urgent/create";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  UpdateScrap: function (data) {
    var url = this.API_BASE_URL + "/scrapMST/update";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .put(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  UpdateAddressType: function (data) {
    var url = this.API_BASE_URL + "/addressTypeMST/update";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .put(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getPartyVoucherByPurchaseId: function (id) {
    var url = this.API_BASE_URL + "/partyVoucher/purchaseId/" + id;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getReceiptBySellId: function (id) {
    var url = this.API_BASE_URL + "/sellReceipt/sellId/" + id;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getExpenseById: function (id) {
    var url = this.API_BASE_URL + "/miscMST/id/" + id;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getAddressTypeById: function (id) {
    var url = this.API_BASE_URL + "/addressTypeMST/id/" + id;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getAddressType: function () {
    var url = this.API_BASE_URL + "/addressTypeMST/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getReminderPreference: function () {
    var url = this.API_BASE_URL + "/reminderPreference/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getCountry: function () {
    var url = this.API_BASE_URL + "/countryMST/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getScrap: function () {
    var url = this.API_BASE_URL + "/scrapMST/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getState: function () {
    var url = this.API_BASE_URL + "/stateMST/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getDistrict: function () {
    var url = this.API_BASE_URL + "/districtMST/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getCity: function () {
    var url = this.API_BASE_URL + "/cityMST/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getTaluka: function () {
    var url = this.API_BASE_URL + "/talukaMST/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getCompany: function () {
    var url = this.API_BASE_URL + "/companyMST/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getModel: function () {
    var url = this.API_BASE_URL + "/modelMST/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getUM: function () {
    var url = this.API_BASE_URL + "/userMST/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getUMRole: function () {
    var url = this.API_BASE_URL + "/userRoleMST/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getVehicleCategory: function () {
    var url = this.API_BASE_URL + "/modelCategotyMST/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getContact: function () {
    var url = this.API_BASE_URL + "/contactMST/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getReminderContact: function () {
    var url = this.API_BASE_URL + "/reminderMST/contactMST/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getAddress: function () {
    var url = this.API_BASE_URL + "/addressMST/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getRc: function () {
    var url = this.API_BASE_URL + "/rcMST/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getRCPurchase: function () {
    var url = this.API_BASE_URL + "/rcMST/allRCDTO";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getPurchase: function () {
    var url = this.API_BASE_URL + "/purchaseMST/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getStock: function () {
    var url = this.API_BASE_URL + "/stockMST/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getDestroyData: function () {
    var url = this.API_BASE_URL + "/demolishMST/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getSell: function () {
    var url = this.API_BASE_URL + "/sellMST/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getNORCPurchase: function () {
    var url = this.API_BASE_URL + "/purchaseMST/getAll";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getPartyVoucher: function () {
    var url = this.API_BASE_URL + "/partyVoucher/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getReceipt: function () {
    var url = this.API_BASE_URL + "/sellReceipt/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getSellData: function () {
    var url = this.API_BASE_URL + "/sellMST/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getExpenseData: function () {
    var url = this.API_BASE_URL + "/miscMST/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getReminderData: function () {
    var url = this.API_BASE_URL + "/reminderMST/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  sendReminderById: function (id) {
    var url = this.API_BASE_URL + "/reminderMST/sendReminder/" + id;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  sendTotalReminderByCustomerId: function (id) {
    var url = this.API_BASE_URL + "/reminderMST/totalDue/sendReminder/" + id;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getReminderPrefById: function (id) {
    var url = this.API_BASE_URL + "/reminderPreference/id/" + id;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getCountryById: function (id) {
    var url = this.API_BASE_URL + "/countryMST/id/" + id;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },

  getStateById: function (id) {
    var url = this.API_BASE_URL + "/stateMST/id/" + id;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getDistrictById: function (id) {
    var url = this.API_BASE_URL + "/districtMST/id/" + id;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getCityById: function (id) {
    var url = this.API_BASE_URL + "/cityMST/id/" + id;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },

  getTalukaById: function (id) {
    var url = this.API_BASE_URL + "/talukaMST/id/" + id;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getCompanyById: function (id) {
    var url = this.API_BASE_URL + "/companyMST/id/" + id;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getModelById: function (id) {
    var url = this.API_BASE_URL + "/modelMST/id/" + id;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getVehicleCategoryById: function (id) {
    var url = this.API_BASE_URL + "/modelCategotyMST/id/" + id;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getContactById: function (id) {
    var url = this.API_BASE_URL + "/contactMST/id/" + id;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getAddressById: function (id) {
    var url = this.API_BASE_URL + "/addressMST/id/" + id;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getRCById: function (id) {
    var url = this.API_BASE_URL + "/rcMST/id/" + id;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getAddressByContactId: function (id) {
    var url = this.API_BASE_URL + "/addressMST/contactId/" + id;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getPurchaseById: function (id) {
    var url = this.API_BASE_URL + "/purchaseMST/id/" + id;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getPurchaseByRCId: function (id) {
    var url = this.API_BASE_URL + "/purchaseMST/rcId/" + id;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getSellById: function (id) {
    var url = this.API_BASE_URL + "/sellMST/id/" + id;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getUMById: function (id) {
    var url = this.API_BASE_URL + "/userMST/id/" + id;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getPartyVoucherById: function (id) {
    var url = this.API_BASE_URL + "/partyVoucher/id/" + id;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getReceiptById: function (id) {
    var url = this.API_BASE_URL + "/sellReceipt/id/" + id;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  deleteReminderPrefById: function (id) {
    var url = this.API_BASE_URL + "/reminderPreference/delete/" + id;
    return axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  deleteCountryById: function (id) {
    var url = this.API_BASE_URL + "/countryMST/delete/" + id;
    return axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  deleteStateById: function (id) {
    var url = this.API_BASE_URL + "/stateMST/delete/" + id;
    return axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },

  deleteDistrictById: function (id) {
    var url = this.API_BASE_URL + "/districtMST/delete/" + id;
    return axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  deleteCityById: function (id) {
    var url = this.API_BASE_URL + "/cityMST/delete/" + id;
    return axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },

  deleteTalukaById: function (id) {
    var url = this.API_BASE_URL + "/talukaMST/delete/" + id;
    return axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  deleteCompanyById: function (id) {
    var url = this.API_BASE_URL + "/companyMST/delete/" + id;
    return axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  deleteModelById: function (id) {
    var url = this.API_BASE_URL + "/modelMST/delete/" + id;
    return axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },

  deleteVehicleCategoryById: function (id) {
    var url = this.API_BASE_URL + "/modelCategotyMST/delete/" + id;
    return axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  deleteContactById: function (id) {
    var url = this.API_BASE_URL + "/contactMST/delete/" + id;
    return axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  deleteAddressById: function (id) {
    var url = this.API_BASE_URL + "/addressMST/delete/" + id;
    return axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  deleteAddressTypeById: function (id) {
    var url = this.API_BASE_URL + "/addressTypeMST/delete/" + id;
    return axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  deleteRCById: function (id) {
    var url = this.API_BASE_URL + "/rcMST/delete/" + id;
    return axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  deleteUMById: function (id) {
    var url = this.API_BASE_URL + "/userMST/delete/" + id;
    return axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  deletePurchaseById: function (id) {
    var url = this.API_BASE_URL + "/purchaseMST/delete/" + id;
    return axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  deleteSellById: function (id) {
    var url = this.API_BASE_URL + "/sellMST/delete/" + id;
    return axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  deletePartyVoucherById: function (id) {
    var url = this.API_BASE_URL + "/partyVoucher/delete/" + id;
    return axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  deleteReceiptById: function (id) {
    var url = this.API_BASE_URL + "/sellReceipt/delete/" + id;
    return axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  deleteExpenseById: function (id) {
    var url = this.API_BASE_URL + "/miscMST/delete/" + id;
    return axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  updateContact: function (data) {
    var url = this.API_BASE_URL + "/contactMST/update";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .put(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  updateAddress: function (data) {
    var url = this.API_BASE_URL + "/addressMST/updateALL";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .put(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  updatePurchase: function (data) {
    var url = this.API_BASE_URL + "/purchaseMST/update";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .put(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  updateSell: function (data) {
    var url = this.API_BASE_URL + "/sellMST/update";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .put(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  updatePartyVoucher: function (data) {
    var url = this.API_BASE_URL + "/partyVoucher/update";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .put(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  updateReceipt: function (data) {
    var url = this.API_BASE_URL + "/sellReceipt/update";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .put(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  updateExpense: function (data) {
    var url = this.API_BASE_URL + "/miscMST/update";
    console.log("In getLogin API CALLs: ", data);
    // console.log("In getLogin API CALLs encodedData: ",encodedData);
    return axios
      .put(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  uploadExpenseImage: function (file, id) {
    var url = this.API_BASE_URL + "/files/miscMST/photoProof";
    var formData = new FormData();
    var filename = file.name;
    var ext = filename.substr(filename.lastIndexOf(".") + 1);
    formData.append("file", file, id + "." + ext);
    return axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  uploadContactIdImage: function (file, id) {
    var url = this.API_BASE_URL + "/files/contactMST/idProof";
    var formData = new FormData();
    var filename = file.name;
    var ext = filename.substr(filename.lastIndexOf(".") + 1);
    formData.append("file", file, id + "." + ext);
    return axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  uploadAddressProofImage: function (file, id) {
    var url = this.API_BASE_URL + "/files/contactMST/addressProof";
    var formData = new FormData();
    var filename = file.name;
    var ext = filename.substr(filename.lastIndexOf(".") + 1);
    formData.append("file", file, id + "." + ext);
    return axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  uploadPhotoImage: function (file, id) {
    var url = this.API_BASE_URL + "/files/contactMST/photoProof";
    var formData = new FormData();
    var filename = file.name;
    var ext = filename.substr(filename.lastIndexOf(".") + 1);
    formData.append("file", file, id + "." + ext);
    return axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  uploadSignImage: function (file, id) {
    var url = this.API_BASE_URL + "/files/contactMST/signProof";
    var formData = new FormData();
    var filename = file.name;
    var ext = filename.substr(filename.lastIndexOf(".") + 1);
    formData.append("file", file, id + "." + ext);
    return axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  uploadPurchaseIdImage: function (file, id) {
    var url = this.API_BASE_URL + "/purchaseIdImageMST/uploadFileIdImage";
    var formData = new FormData();
    var filename = file.name;
    var ext = filename.substr(filename.lastIndexOf(".") + 1);
    formData.append("file", file, id + "." + ext);
    return axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  uploadPurchaseAddressProofImage: function (file, id) {
    var url =
      this.API_BASE_URL + "/purchaseAddressproofMST/uploadFileAddressProof";
    var formData = new FormData();
    var filename = file.name;
    var ext = filename.substr(filename.lastIndexOf(".") + 1);
    formData.append("file", file, id + "." + ext);
    return axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  uploadPurchasePhotoImage: function (file, id) {
    var url = this.API_BASE_URL + "/purchasePhotoMST/uploadFilePhoto";
    var formData = new FormData();
    var filename = file.name;
    var ext = filename.substr(filename.lastIndexOf(".") + 1);
    formData.append("file", file, id + "." + ext);
    return axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  uploadPurchaseSignImage: function (file, id) {
    var url = this.API_BASE_URL + "/purchaseSignMST/uploadFileSign";
    var formData = new FormData();
    var filename = file.name;
    var ext = filename.substr(filename.lastIndexOf(".") + 1);
    formData.append("file", file, id + "." + ext);
    return axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getPurchaseNo: function () {
    var url = this.API_BASE_URL + "/purchaseMST/getPurchaseNo";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getSellNo: function () {
    var url = this.API_BASE_URL + "/sellMST/getSellCode";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getCompanyCode: function () {
    var url = this.API_BASE_URL + "/companyMST/getCompanyCode";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getVehicaleCategoryCode: function () {
    var url = this.API_BASE_URL + "/vehicleCategotyMST/getVehicleCode";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getModelCode: function () {
    var url = this.API_BASE_URL + "/modelMST/getModelCode";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getPartyVoucherCode: function () {
    var url = this.API_BASE_URL + "/partyVoucher/getVoucherCode";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getPartyReceiptCode: function () {
    var url = this.API_BASE_URL + "/partyVoucher/getReceiptCode";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getRCFilter: function (criteria) {
    var url = this.API_BASE_URL + "/rcMST/filter/?" + criteria;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getPurchaseFilter: function (criteria) {
    var url = this.API_BASE_URL + "/purchaseMST/filter/?" + criteria;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getscrapFilter: function (criteria) {
    var url = this.API_BASE_URL + "/scrapMST/filter/?" + criteria;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getDestroyFilter: function (criteria) {
    var url = this.API_BASE_URL + "/demolishMST/filter/?" + criteria;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getExpenseFilter: function (criteria) {
    var url = this.API_BASE_URL + "/miscMST/filter/?" + criteria;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getSellFilter: function (criteria) {
    var url = this.API_BASE_URL + "/sellMST/filter/?" + criteria;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getReportFilter: function (criteria) {
    var url = this.API_BASE_URL + "/report/filter/?" + criteria;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getStockFilter: function (criteria) {
    var url = this.API_BASE_URL + "/stockMST/filter/?" + criteria;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getPartyVoucherFilter: function (criteria) {
    var url = this.API_BASE_URL + "/partyVoucher/filter/?" + criteria;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getStockReceiptFilter: function (criteria) {
    var url = this.API_BASE_URL + "/sellReceipt/filter/?" + criteria;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getReminderMSTFilter: function (criteria) {
    var url = this.API_BASE_URL + "/reminderMST/filter/?" + criteria;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getMissingDocReport: function () {
    var url = this.API_BASE_URL + "/missingDoc/all";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getMissingDocReportFilter: function (criteria) {
    var url = this.API_BASE_URL + "/missingDoc/filter/?" + criteria;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getProfitLossFilter: function (criteria) {
    var url = this.API_BASE_URL + "/report/profitLoss/?" + criteria;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getOutStandingFilter: function (criteria) {
    var url = this.API_BASE_URL + "/outStandingBal/filter/?" + criteria;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getDashboardCount: function () {
    var url = this.API_BASE_URL + "/dashboard/count";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getTopVendorChart: function (count) {
    var url = this.API_BASE_URL + "/dashboard/top/" + count;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getProfitLossChart: function (interval) {
    var url = this.API_BASE_URL + "/dashboard/profitLoss/" + interval;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  getScrapProfitLossChart: function (interval) {
    var url = this.API_BASE_URL + "/dashboard/scrapMST/profitLoss/" + interval;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  clearPurchaseDuePayment: function (id, amount) {
    var url = this.API_BASE_URL + "/purchaseMST/clearDue/" + id + "/" + amount;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
  clearSalesDuePayment: function (id, amount) {
    var url = this.API_BASE_URL + "/sellMST/clearDue/" + id + "/" + amount;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(ApiUtils.checkStatus)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (!error.status) {
          return ApiUtils.getCustomEmptyResponse("Network Error");
        } else {
          return ApiUtils.getCustomEmptyResponse(error.response);
        }
      });
  },
};

export { HttpAPI as default };
